import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { RELEASE_COUNTRY } from './env';
import resources from './translations';

export let fallbackLng = 'en';
if (RELEASE_COUNTRY === 'cz') {
  fallbackLng = 'cs';
} else if (RELEASE_COUNTRY === 'de') {
  fallbackLng = 'de';
}

// @ts-ignore
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['navigator', 'htmlTag'],

      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },
    resources,
    fallbackLng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: '.',
    // @ts-ignore
    missingKeyHandler(lng, ns, key, fallbackValue) {
      console.log({ lng, ns, key, fallbackValue });
    },
  });

export default i18n;
