import { setContext } from 'apollo-link-context';
import { AUTH_TOKEN } from '../../../../env';

export default () =>
  setContext(async (_, { headers }) => {
    const requestName = 'authorization';
    const storageKey = AUTH_TOKEN;
    const storedToken = await localStorage.getItem(storageKey);
    const token = storedToken ? JSON.parse(storedToken) : null;

    return {
      headers: {
        ...headers,
        [requestName]: token ? `Bearer ${token}` : '',
      },
    };
  }) as any;
