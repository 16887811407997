import { PureQueryOptions } from 'apollo-client';
import gql from 'graphql-tag';
import { useLazyQuery } from 'react-apollo';
import { DocumentTitlesByIdsDocument } from '../generated/graphql';
import { FolderType, IDocument, IFolderHierarchyOnQueryArguments, IGenericFolder, ITemplate } from '../graphQlSchema';
import { client } from '../providers/ApolloProvider';
import { QueryType } from '../types';
import { getFolderType, PossibleFolderType } from '../utils/getFolderType';

export const QUERY_NAVIGATION = gql`
  query Navigation($type: FolderType!) {
    folderHierarchy(type: $type)
    folderHierarchySamples(type: $type)
  }
`;

export function useLazyNavigationQuery<Item = IDocument | ITemplate>({ type }: { type: QueryType | string }) {
  return useLazyQuery<
    {
      folderHierarchy: IGenericFolder<Item>;
      folderHierarchySamples: IGenericFolder<Item>[];
    },
    IFolderHierarchyOnQueryArguments
  >(QUERY_NAVIGATION, {
    variables: { type: type === 'template' ? FolderType.TEMPLATES : FolderType.DOCUMENTS },
    onCompleted: d => {
      const folderHierarchies = [d.folderHierarchy, ...d.folderHierarchySamples];
      const items: Item[] = [];
      const extractItems = (folder: IGenericFolder<Item>) => {
        Array.isArray(folder.children) &&
          folder.children.forEach(child => {
            extractItems(child);
          });
        Array.isArray(folder.items) &&
          folder.items.forEach(item => {
            items.push({ __typename: 'Document', folder: folder.id, ...item });
          });
      };
      folderHierarchies.forEach(extractItems);

      localStorage.setItem('DocumentTitlesByIds', JSON.stringify(items));
      client.writeQuery({ query: DocumentTitlesByIdsDocument, data: { result: items } });
    },
  });
}

export function refetchQueryNavigation(type: PossibleFolderType): PureQueryOptions {
  const folderType = getFolderType(type);
  return {
    query: QUERY_NAVIGATION,
    variables: { type: folderType },
  };
}
