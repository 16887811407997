import gql from 'graphql-tag';

export const MESSAGES_QUERY = gql`
  query Messages {
    messages {
      id
      subject
      body
    }
  }
`;

export const MESSAGE_INBOX_QUERY = gql`
  query MessageInbox {
    messages: inboxMessages {
      id
      subject
      body
      sender
      recipient
      readAt
      sentAt
    }
  }
`;
