// custom implementation based on apollo-boost
// many ideas based on this comment
// @see https://github.com/benseitz/apollo-link-offline/issues/1#issuecomment-371678922

import ApolloClient from 'apollo-client';
import cacheBase from './cache';
import { createLink } from './link';

export default class DefaultClient<TCache> extends ApolloClient<TCache> {
  constructor() {
    const cache = cacheBase();

    // super hacky, we will fix the types eventually
    super({
      cache,
      link: createLink({ cache }),
      resolvers: [],
      defaultOptions: {
        watchQuery: {
          // useful to get queries first from cache then from network
          fetchPolicy: 'cache-and-network',
          // handle errors from
          errorPolicy: 'all',
        },
      },
    } as any);

    cache.writeData({
      data: {
        createDrawerOpen: false,
        searchDrawerOpen: false,
        exportDrawerOpen: false,
      },
    });
  }
}
