import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../../../generated/graphql';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default () => {
  return new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object: any) => {
      if (object.__typename) {
        switch (object.__typename) {
          case 'ContentValue':
            return null; // do not cache type ContentValue
        }
        if (object.id !== undefined) {
          return `${object.__typename}:${object.id}`;
        }
        if (object._id !== undefined) {
          return `${object.__typename}:${object._id}`;
        }
      }
      return null;
    },
    cacheRedirects: {
      Query: {
        document: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Document', id: args.id }),
        documents: (_, args, { getCacheKey }) => {
          if (args) {
            const { input } = args;
            if (input && Array.isArray(input.ids) && input.ids[0] !== '') {
              return input.ids.map((id: any) => getCacheKey({ __typename: 'Document', id }));
            }
          }
          return null;
        },
      },
    },
  });
};
