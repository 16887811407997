import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ICreateMessageOnMutationArguments, IMessage, IMessageInput } from '../graphQlSchema';
import { MESSAGES_QUERY } from './queries';

const CREATE_MESSAGES = gql`
  mutation CreateMessage($input: MessageInput!) {
    createdMessage: createMessage(input: $input) {
      id
      subject
      body
      sender
      readAt
      recipient
      sentAt
    }
  }
`;

export const useCreateMessage = () => {
  const [createMessage] = useMutation<{ createdMessage: IMessage }, ICreateMessageOnMutationArguments>(
    CREATE_MESSAGES,
    {
      update: (cache, { data }) => {
        const cachedQueries = cache.readQuery({ query: MESSAGES_QUERY }) as any;
        if (data && data.createdMessage && cachedQueries && cachedQueries.result) {
          const { createdMessage } = data;
          cache.writeQuery({
            query: MESSAGES_QUERY,
            data: { messages: [...cachedQueries.result, createdMessage] },
          });
        }
      },
    }
  );

  return (input: IMessageInput) => createMessage({ variables: { input } });
};
