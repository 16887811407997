import { useQuery } from '@apollo/react-hooks';
import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import {
  CheckLoginDocument,
  CheckLoginQuery,
  CheckLoginQueryVariables,
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  User as IUser,
} from '../../generated/graphql';
import { LoginValues } from './types';

function isAfterLogin(arg: any): arg is CheckLoginQuery {
  return arg.login !== undefined;
}

interface Props extends LoginValues {
  onReset: () => void;
  onError: () => void;
  children: ReactNode;
  authToken: string | null;
  setAuthToken: (token: string) => void;
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

const LoginQuery: FunctionComponent<Props> = ({
  username,
  password,
  onError,
  children,
  authToken,
  setAuthToken,
  user,
  setUser,
}) => {
  const { t } = useTranslation('Authorization');
  const { loading, error, data } = useQuery<
    CheckLoginQuery | GetUserQuery,
    CheckLoginQueryVariables | GetUserQueryVariables
  >(authToken && authToken !== '' && user ? GetUserDocument : CheckLoginDocument, {
    variables: { username, password, userId: user ? user.id : '' },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader>{t('validate')}</Loader>;
  }
  if (error) {
    onError();
    return null;
  }

  if (isAfterLogin(data)) {
    const { login }: CheckLoginQuery = data || {};
    if (login && login.token) {
      setAuthToken(login.token);
      setUser(login.user as IUser);
    } else {
      onError();
    }
  }

  return <Fragment>{children}</Fragment>;
};

export default LoginQuery;
