import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { IMarkReadMessageOnMutationArguments, IMessage } from '../graphQlSchema';
import { MESSAGES_QUERY } from './queries';

const MARK_READ = gql`
  mutation MarkRead($id: ID!) {
    markReadMessage(id: $id) {
      id
      readAt
    }
  }
`;

export const useMessages = () => {
  const messages = useQuery<{ messages: IMessage[] }>(MESSAGES_QUERY);
  const [setMarkReadMessage] = useMutation<{ markReadMessage: IMessage }, IMarkReadMessageOnMutationArguments>(
    MARK_READ
  );

  return [messages, (variables: IMarkReadMessageOnMutationArguments) => setMarkReadMessage({ variables })] as const;
};
