import { createUploadLink } from 'apollo-upload-client';

const uri = process.env.REACT_APP_API_URL;

export default () =>
  createUploadLink({
    uri,
    fetch,
    fetchOptions: {},
    credentials: 'same-origin',
    headers: {},
  });
