import { LocationProvider, RouteComponentProps } from '@reach/router';
import React, { lazy, ReactNode, Suspense } from 'react';
import styled from 'styled-components';
import { RELEASE_COUNTRY } from '../env';

const ContentPages: { [key: string]: any } = {
  de: lazy(() => import(/* webpackChunkName: "content/de" */ './de/index')),
  cz: lazy(() => import(/* webpackChunkName: "content/cz" */ './cz/index')),
};

export const ContentRoute = ({ children, ...props }: { children: ReactNode } & RouteComponentProps) => <>{children}</>;

export const ContentFallback = ({ children, ...props }: { children: ReactNode } & RouteComponentProps) => (
  <div>
    <div>
      <div>{children}</div>
    </div>
  </div>
);

const StyledWrapper = styled.div`
  min-height: 100%;

  &,
  & > div:first-child,
  & > div:first-child > div:first-child,
  & > div:first-child > div:first-child > div:first-child {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
  }
`;

export function ContentLoader({ children }: { children?: ReactNode }) {
  const Content = ContentPages[RELEASE_COUNTRY] || ContentFallback;

  return (
    <LocationProvider>
      <StyledWrapper>
        <Suspense fallback={<div>Loading...</div>}>
          <Content children={children} />
        </Suspense>
      </StyledWrapper>
    </LocationProvider>
  );
}
