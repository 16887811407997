import { onError } from 'apollo-link-error';
import { AUTH_TOKEN } from '../../../../env';

export default () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === '401: Unauthorized') {
          window.localStorage.removeItem(AUTH_TOKEN as string);
        }
        // tslint:disable-next-line
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }
    if (networkError) {
      // tslint:disable-next-line
      console.log(`[Network error]: ${networkError}`);
    }
  });
