import { ApolloCache } from 'apollo-cache';
import { ApolloLink } from 'apollo-link';
import authLink from './authLink';
import errorLink from './errorLink';
import loggerLink from './loggerLink';
import httpLink from './uploadHttpLink';

export const createLink = ({ cache }: { cache: ApolloCache<any> }) =>
  ApolloLink.from([
    errorLink(),
    loggerLink(),
    // retryLink(),
    // offlineLink(),
    authLink(),
    httpLink(),
  ].filter(x => !!x) as ApolloLink[]);
