import Button from '@atlaskit/button';
import { LabelInner } from '@atlaskit/field-base/dist/cjs/styled/Label';
import FieldText from '@atlaskit/field-text';
import { h400, h900 } from '@atlaskit/theme/dist/cjs/typography';
import { RouteComponentProps } from '@reach/router';
import { Field, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { login as Image } from '../../assets';
import { LOST_PASSWORD_LINK } from '../../env';
import { cssMedia } from '../../utils/cssMedia';
import { ErrorMessage } from './ErrorMessage';
import { LoginValues } from './types';

const StyledWrapperCenter = styled.div`
  min-height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapperGrid = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 5vw;
  ${cssMedia.minDesktop`
    padding:0;
    display:grid;
    grid-column-gap: 5vw;
    grid-template-columns: [left] auto [right] auto;
    align-items: center;
  `};
`;

const StyledLoginWrapper = styled.form`
  display: grid;
  grid-row-gap: 20px;
  grid-column: left;
  justify-self: end;
  ${cssMedia.minDesktop`
    max-width: calc(100% - 50px);
  `};
`;

const StyledFieldWrapper = styled.div`
  ${LabelInner} {
    padding-top: 0;
  }
  // Safari on iOS zooms in on inputs with a font size lower than 16
  input {
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  text-align: center;
  display: block;
  width: 100%;
  height: 40px;
  justify-content: center;
`;

const StyledLink = styled.a`
  display: inline-block;
`;

const StyledImageWrapper = styled.div`
  grid-column: right;
  justify-self: start;
`;

const Title = styled.div`
  ${h900};
  margin-top: 0;
`;
const Subtitle = styled.div`
  ${h400};
`;

const Visual = styled.img`
  display: none;
  @media only screen and (min-width: 900px) {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 360px;
    max-height: 360px;
  }
`;

const handleValidation = (values: LoginValues) => {
  const errors: any = {};
  if (!values.username) {
    errors.username = 'Required';
  }
  return errors;
};

interface Props extends RouteComponentProps {
  onSubmit: (values: LoginValues, formikActions: FormikActions<LoginValues>) => void;
  hasError: boolean;
}

const LoginView = ({ onSubmit, hasError }: Props) => {
  const { t } = useTranslation('Authorization');
  return (
    <Formik initialValues={{ username: '', password: '' }} validate={handleValidation} onSubmit={onSubmit}>
      {(props: FormikProps<LoginValues>) => (
        <StyledWrapperCenter>
          <StyledWrapperGrid>
            <StyledLoginWrapper onSubmit={props.handleSubmit}>
              <div>
                <Title>{t('headline')}</Title>
                <Subtitle>{t('description')}</Subtitle>
                {hasError && <ErrorMessage>{t('errorMessage')}</ErrorMessage>}
              </div>
              <Field name="username">
                {({ field }: any) => (
                  <StyledFieldWrapper>
                    <FieldText
                      shouldFitContainer
                      label={t('username')}
                      id={'username'}
                      autocomplete="username"
                      {...field}
                    />
                  </StyledFieldWrapper>
                )}
              </Field>
              <Field name="password">
                {({ field }: any) => (
                  <StyledFieldWrapper>
                    <FieldText
                      shouldFitContainer
                      label={t('password')}
                      id={'password'}
                      autocomplete="current-password"
                      type="password"
                      {...field}
                    />
                  </StyledFieldWrapper>
                )}
              </Field>
              <StyledButton appearance={'primary'} type="submit">
                {t('login')}
              </StyledButton>
              {LOST_PASSWORD_LINK !== '' && <StyledLink href={LOST_PASSWORD_LINK}>{t('lostPassword')}</StyledLink>}
            </StyledLoginWrapper>
            <StyledImageWrapper>
              <Visual src={Image} />
            </StyledImageWrapper>
          </StyledWrapperGrid>
        </StyledWrapperCenter>
      )}
    </Formik>
  );
};

export default LoginView;
