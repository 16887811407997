import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ICreateMassMessageOnMutationArguments, IMassMessageInput } from '../graphQlSchema';

const CREATE_MASS_MESSAGES = gql`
  mutation CreateMassMessage($input: MassMessageInput!) {
    createdMassMessage: createMassMessage(input: $input) {
      subject
    }
  }
`;

export const useCreateMassMessage = () => {
  const [createMassMessage] = useMutation<any, ICreateMassMessageOnMutationArguments>(CREATE_MASS_MESSAGES);

  return (input: IMassMessageInput) => createMassMessage({ variables: { input } });
};
