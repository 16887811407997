import { useCallback, useMemo } from 'react';
import { useBrowserLocale } from '.';
import { DEFAULT_CURRENCY_STYLE } from '../env';

export const currencyStyle = {
  EUR: '€',
  USD: '$',
  CZK: 'CZK',
} as const;

export type CurrencyStyle = keyof typeof currencyStyle;

export function useCurrencyStyle(currency: CurrencyStyle = DEFAULT_CURRENCY_STYLE as CurrencyStyle) {
  const browserLocale = useBrowserLocale();
  const n = new Intl.NumberFormat(browserLocale, { style: 'currency', currency });
  const style = useMemo(() => {
    try {
      return ((n as any).formatToParts(1) as {
        type: 'currency' | 'integer' | 'group' | 'integer' | 'decimal' | 'fraction';
        value: string;
      }[]).find(({ type }) => type === 'currency')!.value;
    } catch (ex) {
      if (Object.keys(currencyStyle).includes(currency)) {
        return currencyStyle[currency];
      }
      // tslint:disable-next-line:no-console
      console.error(ex);
      return currency;
    }
  }, [currency, n]);
  const format = useCallback((value: number) => n.format(value), [n]);
  return [style, format] as const;
}
