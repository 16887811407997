import { createContext, useContext } from 'react';

interface IDocumentInfoContext {
  id: string;
  version?: number;
  isSet: boolean;
  moduleAccess?: string;
}

const DocumentInfo = createContext<IDocumentInfoContext>({ id: '', isSet: false, moduleAccess: '' });

export const useDocumentInfo = () => {
  return useContext(DocumentInfo);
};

export default DocumentInfo.Provider;
