import { ReactElementLike } from 'prop-types';
import React, { createContext, FunctionComponent, useState } from 'react';
import { ContentLoader } from '../../content';
import { AUTH_TOKEN } from '../../env';
import { Customer as ICustomer, User as IUser } from '../../generated/graphql';
import { useLocalStorage } from '../../hooks';
import { useUser } from '../../hooks/useUser';
import LoginQuery from './LoginQuery';
import LoginView from './LoginView';
import { LoginValues } from './types';

interface Props {
  children: ReactElementLike;
}

interface IAuthorizationContext {
  user: Partial<IUser> | null;
  customer: Partial<ICustomer> | null;
  logout: () => void;
}

export const Authorization = createContext<IAuthorizationContext>({
  user: null,
  customer: null,
  logout: () => null,
});

const AuthorizationProvider: FunctionComponent<Props> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sendCredentials, setSendCredentials] = useState(false);
  const [authToken, setAuthToken] = useLocalStorage(AUTH_TOKEN);
  // const [user, setUser] = useLocalStorage('user');
  const [user, setUser] = useUser();
  const hasCredentials = (authToken && authToken !== '' && user) || false;

  const handleSubmit = ({ username: u, password: p }: LoginValues) => {
    setUsername(u.trim());
    setPassword(p.trim());
    setSendCredentials(true);
  };

  const handleReset = () => {
    setUsername('');
    setPassword('');
    setUser(null);
    setAuthToken('');
    setSendCredentials(false);
    setHasError(false);
  };

  const handleError = () => {
    handleReset();
    sendCredentials && setHasError(true);
  };

  const handleLogout = () => {
    handleReset();
    document.location.href = '/';
  };

  return !sendCredentials && !hasCredentials ? (
    <ContentLoader>
      <LoginView default onSubmit={handleSubmit} hasError={hasError} />
    </ContentLoader>
  ) : (
    <LoginQuery
      username={username}
      password={password}
      onReset={handleReset}
      onError={handleError}
      authToken={authToken}
      setAuthToken={setAuthToken}
      user={user as IUser}
      setUser={setUser}
    >
      <Authorization.Provider
        value={{
          customer: user && user.customer ? user.customer : null,
          user,
          logout: handleLogout,
        }}
      >
        {children}
      </Authorization.Provider>
    </LoginQuery>
  );
};

export default AuthorizationProvider as any;
