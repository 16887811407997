import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/dist/cjs/colors';

import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ErrorMessageProps {
  children: ReactNode;
  className?: string;
}

export const ErrorMessage = styled<ErrorMessageProps>(({ children, className }) => (
  <p className={className}>
    <ErrorIcon label={''} />
    <span>{children}</span>
  </p>
))`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.32em;
  font-weight: bold;
  color: ${R400};
`;
