import { Dispatch, SetStateAction, useState } from 'react';

function useStorage<S>(
  key: string,
  type: 'localStorage' | 'sessionStorage',
  initialState?: S
): [S, Dispatch<SetStateAction<S>>] {
  let storageItem;
  if (key) {
    // Try to parse, otherwise use raw value
    try {
      storageItem = JSON.parse(window[type][key]);
    } catch (_) {
      storageItem = window[type][key];
    }

    // if not stored value is found use initial value
    if (!storageItem) {
      storageItem = initialState;
    }
  }
  const [localState, updateLocalState] = useState(storageItem);
  // TODO: strange bug in IE, disabled eventListener
  /* const syncLocalStorage = useCallback(
    (event: StorageEventInit) => {
      if (event.key === key && event.newValue) {
        updateLocalState(event.newValue);
      }
    },
    [updateLocalState, key]
  );
  useEffect(
    () => {
      window.addEventListener('storage', syncLocalStorage);
      return () => {
        window.removeEventListener('storage', syncLocalStorage);
      };
    },
    [sy ncLocalStorage]
  );*/

  const setItem = (value: any) => {
    window[type].setItem(key, JSON.stringify(value));
    updateLocalState(value);
  };

  return [localState, setItem];
}

export function useLocalStorage<S>(key: string, initialState?: S): [S, Dispatch<SetStateAction<S>>] {
  return useStorage(key, 'localStorage', initialState);
}

export function useSessionStorage<S>(key: string, initialState?: S): [S, Dispatch<SetStateAction<S>>] {
  return useStorage(key, 'sessionStorage', initialState);
}
