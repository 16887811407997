let assets: any = {};

try {
  assets = require('./assets');
} catch (ex) {
  throw ex;
}

export const icon = assets.icon ?? '';
export const login = assets.login ?? '';
export const favicon = assets.favicon ?? '';
