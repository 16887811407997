import { FormEvent, useCallback, useState } from 'react';
import { FieldBaseProps } from '../components/FieldBase';
import { useDebounce } from './useDebounce';

export function useFieldValue<T extends string | null>(
  fieldValue: T,
  {
    name,
    delay = 300,
    updateField = val => val,
    setFieldValue,
    setFieldTouched,
  }: {
    name?: string;
    delay?: number;
    /** updateField onChange */
    updateField?: (value: T) => T;
  } & Pick<FieldBaseProps, 'setFieldValue'> &
    Pick<FieldBaseProps, 'setFieldTouched'>
): [
  T,
  { onChange: (event: FormEvent<HTMLInputElement>) => void; onBlur: (event: FormEvent<HTMLInputElement>) => void }
] {
  const [value, setValue] = useState<T>(fieldValue);

  if (process.env.NODE_ENV === 'development') {
    if (!setFieldValue) {
      // tslint:disable-next-line:no-console
      console.warn(`missing 'setFieldValue' on ${name}`);
    }
    if (!setFieldTouched) {
      // tslint:disable-next-line:no-console
      console.warn(`missing 'setFieldTouched' on ${name}`);
    }
  }

  useDebounce(
    () => {
      if (value !== fieldValue) {
        setFieldValue && setFieldValue(name!, value + '');
      }
    },
    delay,
    [value]
  );

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement> | string) => {
      const currentValue = typeof event === 'string' ? event : event.currentTarget.value;
      setValue(updateField(currentValue as T));
    },
    [updateField]
  );

  const handleBlur = useCallback(() => {
    if (name) {
      setFieldValue && setFieldValue!(name, value + '');
      setFieldTouched && setFieldTouched!(name);
    }
  }, [name, setFieldTouched, setFieldValue, value]);

  return [value, { onChange: handleChange as any, onBlur: handleBlur as any }];
}
