import { Document } from './generated/graphql';

export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN || 'authToken';
export const BUGSNAG = process.env.REACT_APP_BUGSNAG || '';
export const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER || '';
export const RELEASE_STAGE = process.env.REACT_APP_RELEASE_STAGE || '';
export const TRASHBIN_ID = 'trashbin';
export const RELEASE_COUNTRY = ((RELEASE_STAGE.split('/')[1] || '').split('-')[0] || '') as 'de' | 'cz';
export const LOST_PASSWORD_LINK = process.env.REACT_APP_LOST_PASSWORD_LINK || '';
export const OPTIONAL_CHECKIN_REASONS = process.env.REACT_APP_OPTIONAL_CHECKIN_REASONS === 'true';
export const CHECKIN_REASONS = process.env.REACT_APP_CHECKIN_REASONS?.split('|');
export const HIDE_META_FIELDS: (keyof Document)[] | undefined = process.env.REACT_APP_HIDE_META_FIELDS?.split(
  '|'
) as (keyof Document)[];
export const FLAG_IMPORT_FROM_XLS = Boolean(process.env.REACT_APP_FLAG_IMPORT_FROM_XLS);
export const FLAG_DOWNLOAD_WITH_ATTACHMENTS = Boolean(process.env.REACT_APP_FLAG_DOWNLOAD_WITH_ATTACHMENTS);
export const DEFAULT_CURRENCY_STYLE = process.env.REACT_APP_DEFAULT_CURRENCY_STYLE || 'EUR';
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8080';
