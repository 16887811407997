import '@atlaskit/css-reset';
import React from 'react';
import Loadable from 'react-loadable';
import Loader from './components/Loader';
import { ApolloProvider, AuthorizationProvider, ThemeProvider } from './providers';

const LoadableApp = Loadable({
  loader: () => import(/* webpackChunkName: "app" */ './App'),
  loading: ({ error }) => {
    if (process.env.NODE_ENV === 'development') {
      if (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
      }
    }
    return <Loader />;
  },
});

export default () => (
  <ThemeProvider>
    <ApolloProvider>
      <AuthorizationProvider>
        <LoadableApp />
      </AuthorizationProvider>
    </ApolloProvider>
  </ThemeProvider>
);
