import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { IDeleteTemplateOnMutationArguments } from '../graphQlSchema';
import { refetchQueryNavigation } from '../queries/NavigationQuery';

const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($id: ID!) {
    isDeleted: deleteTemplate(id: $id)
  }
`;

export function useDeleteTemplate(variables: IDeleteTemplateOnMutationArguments) {
  const [deleteTemplate] = useMutation<{ isDeleted: boolean }, IDeleteTemplateOnMutationArguments>(DELETE_TEMPLATE, {
    refetchQueries: [refetchQueryNavigation('template')],
    awaitRefetchQueries: true,
  });
  return async () => {
    const { data } = await deleteTemplate({ variables });
    return data && data.isDeleted;
  };
}
