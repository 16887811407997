import * as CustomTypes from '../types';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** The `DocumentsTree` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  DocumentsTree: CustomTypes.TreeStructure<
    Pick<CustomTypes.Folder, 'id' | 'title'> & { isReadOnly?: boolean },
    Pick<Document, 'id' | 'title'>
  >;
  /** The `TemplatesTree` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  TemplatesTree: CustomTypes.TreeStructure<
    Pick<CustomTypes.Folder, 'id' | 'title'> & { isReadOnly?: boolean },
    Pick<Template, 'id' | 'title'>
  >;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: string;
  /** Use JavaScript Date object for date/time fields. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export interface AccessGroup {
  __typename?: 'AccessGroup';
  id: Scalars['ID'];
  title: Scalars['String'];
  users?: Maybe<Array<User>>;
  accessRights?: Maybe<Array<AccessGroupRights>>;
}

export interface AccessGroupRights {
  __typename?: 'AccessGroupRights';
  /** id of the AccessGroupRights */
  id: Scalars['ID'];
  /**
   * custom id of the type
   * for example an id of a folder
   */
  subject: Scalars['ID'];
  type: AccessGroupType;
  access?: Maybe<AccessVariant>;
}

export interface AccessGroupRightsInput {
  type: AccessGroupType;
  subject: Scalars['ID'];
  id?: Maybe<Scalars['String']>;
  access: AccessVariant;
}

export interface AccessGroupsInput {
  id: Scalars['ID'];
  type?: Maybe<AccessGroupType>;
}

export enum AccessGroupType {
  Customer = 'CUSTOMER',
  FolderDocument = 'FOLDER_DOCUMENT',
  FolderTemplate = 'FOLDER_TEMPLATE',
  Unknown = 'UNKNOWN',
}

export enum AccessLevel {
  Full = 'FULL',
  Limited = 'LIMITED',
  Title = 'TITLE',
}

export enum AccessVariant {
  None = 'none',
  Read = 'read',
  Edit = 'edit',
}

export interface ArchiveDocumentAttachments extends Job {
  __typename?: 'ArchiveDocumentAttachments';
  id: Scalars['ID'];
  status: JobStatus;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  result?: Maybe<JobDoneResult>;
  affectedDocuments?: Maybe<Array<Document>>;
  additionalMediaObjects?: Maybe<Array<Scalars['String']>>;
}

export interface ArchiveDocumentAttachmentsInput {
  affectedDocumentIds?: Maybe<Array<Scalars['String']>>;
  additionalMediaObjectIds?: Maybe<Array<Scalars['String']>>;
}

export interface Association {
  __typename?: 'Association';
  id: Scalars['String'];
  title: Scalars['String'];
  creatorTemplateId?: Maybe<Scalars['String']>;
  creatorTemplateTitle?: Maybe<Scalars['String']>;
  customerName: Scalars['String'];
  customerId: Scalars['String'];
  path: Scalars['String'];
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export interface CheckinDataInput {
  reason: Scalars['String'];
  comment: Scalars['String'];
}

export interface ContentsInput {
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
}

export interface ContentValue {
  __typename?: 'ContentValue';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
}

export interface Controller {
  __typename?: 'Controller';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}

export interface CopyDocumentInput {
  title?: Maybe<Scalars['String']>;
}

export interface CopyTemplateInput {
  title?: Maybe<Scalars['String']>;
}

export interface CreateAccessGroupInput {
  title: Scalars['String'];
  accessRights?: Maybe<Array<AccessGroupRightsInput>>;
  users?: Maybe<Array<Scalars['ID']>>;
}

export interface CreateDocumentInput {
  documentDraft: DocumentDraftInput;
  checkinData?: Maybe<CheckinDataInput>;
}

export interface CreateTaskInput {
  relatedDocument: Scalars['String'];
}

export interface Customer {
  __typename?: 'Customer';
  id: Scalars['ID'];
  subscription: Scalars['String'];
  users: Array<User>;
  documents: Array<Document>;
  isExample?: Maybe<Scalars['Boolean']>;
  corporation?: Maybe<Scalars['String']>;
}

export interface Document extends DocumentBase {
  __typename?: 'Document';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  structure: Array<Section>;
  contents: Array<ContentValue>;
  folder?: Maybe<Folder>;
  path?: Maybe<Array<Folder>>;
  drafts: Array<DocumentDraft>;
  relatedTasks?: Maybe<PagedTasks>;
  reason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  outgoingReferences?: Maybe<Array<Document>>;
  incomingReferences?: Maybe<Array<Document>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  documentCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  controller?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  responsibleFor?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Status>;
  nextTaskAt?: Maybe<Scalars['String']>;
  taskSchedulingInterval?: Maybe<Scalars['String']>;
  taskDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['String']>;
  isExample?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<DocumentPermission>>;
  creatorTemplate?: Maybe<Template>;
  taskDueDatePreWarningAmount?: Maybe<Scalars['String']>;
  taskDueDatePreWarningUnit?: Maybe<Scalars['String']>;
  taskDueDatePreWarningDescription?: Maybe<Scalars['String']>;
  accessLevel?: Maybe<Scalars['String']>;
  enablePreWarning: Scalars['Boolean'];
}

export type DocumentBase = {
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  structure: Array<Section>;
  contents: Array<ContentValue>;
  folder?: Maybe<Folder>;
  path?: Maybe<Array<Folder>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  documentCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  controller?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  responsibleFor?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Status>;
  nextTaskAt?: Maybe<Scalars['String']>;
  taskSchedulingInterval?: Maybe<Scalars['String']>;
  taskDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['String']>;
  isExample?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<DocumentPermission>>;
  creatorTemplate?: Maybe<Template>;
  taskDueDatePreWarningAmount?: Maybe<Scalars['String']>;
  taskDueDatePreWarningUnit?: Maybe<Scalars['String']>;
  taskDueDatePreWarningDescription?: Maybe<Scalars['String']>;
  enablePreWarning: Scalars['Boolean'];
};

export enum DocumentCategoryType {
  Confidential = 'confidential',
  Internal = 'internal',
  Open = 'open',
  External = 'external',
}

export interface DocumentCreateInput {
  title?: Maybe<Scalars['String']>;
}

export interface DocumentDraft extends DocumentBase {
  __typename?: 'DocumentDraft';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  structure: Array<Section>;
  contents: Array<ContentValue>;
  folder?: Maybe<Folder>;
  path?: Maybe<Array<Folder>>;
  document?: Maybe<Document>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  documentCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  controller?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  responsibleFor?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Status>;
  nextTaskAt?: Maybe<Scalars['String']>;
  taskSchedulingInterval?: Maybe<Scalars['String']>;
  taskDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['String']>;
  isExample?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<DocumentPermission>>;
  creatorTemplate?: Maybe<Template>;
  taskDueDatePreWarningAmount?: Maybe<Scalars['String']>;
  taskDueDatePreWarningUnit?: Maybe<Scalars['String']>;
  taskDueDatePreWarningDescription?: Maybe<Scalars['String']>;
  enablePreWarning: Scalars['Boolean'];
}

export interface DocumentDraftInput {
  title: Scalars['String'];
  contents?: Maybe<Array<ContentsInput>>;
  documentCategory?: Maybe<DocumentCategoryType>;
  referenceNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  validFrom?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
  responsibleFor?: Maybe<Scalars['String']>;
  controller?: Maybe<Scalars['String']>;
  nextTaskAt?: Maybe<Scalars['String']>;
  taskSchedulingInterval?: Maybe<Scalars['String']>;
  taskDescription?: Maybe<Scalars['String']>;
  taskDueDatePreWarningAmount?: Maybe<Scalars['String']>;
  taskDueDatePreWarningUnit?: Maybe<Scalars['String']>;
  taskDueDatePreWarningDescription?: Maybe<Scalars['String']>;
  enablePreWarning: Scalars['Boolean'];
}

export enum DocumentPermission {
  Get = 'GET',
  CheckOut = 'CHECK_OUT',
  CheckIn = 'CHECK_IN',
  CopyDocument = 'COPY_DOCUMENT',
  CopyDocuments = 'COPY_DOCUMENTS',
  MoveDocument = 'MOVE_DOCUMENT',
  DeleteDocument = 'DELETE_DOCUMENT',
  Search = 'SEARCH',
}

export interface DocumentSearchHighlight {
  __typename?: 'DocumentSearchHighlight';
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  structure?: Maybe<Array<Maybe<Scalars['String']>>>;
  contents?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface DocumentsInput {
  ids?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  /** filter deleted documents */
  deleted?: Maybe<Scalars['Boolean']>;
  /** get examples in the response */
  examples?: Maybe<Scalars['Boolean']>;
  /** gets documents by creatorTemplate */
  creatorTemplate?: Maybe<Scalars['ID']>;
  updatedLastDays?: Maybe<Scalars['Int']>;
}

export interface DocumentsTreeInput {
  where?: Maybe<DocumentsTreeWhereInput>;
  options?: Maybe<DocumentsTreeOptionsInput>;
}

export interface DocumentsTreeOptionsInput {
  excludeEmptyFolders?: Maybe<Scalars['Boolean']>;
}

export type DocumentsTreePayload = DocumentsTreePayloadResult | NotFound;

export interface DocumentsTreePayloadResult {
  __typename?: 'DocumentsTreePayloadResult';
  result?: Maybe<Scalars['DocumentsTree']>;
}

export interface DocumentsTreeWhereInput {
  creatorTemplate?: Maybe<Scalars['ID']>;
  samples?: Maybe<Scalars['Boolean']>;
}

export interface Field {
  __typename?: 'Field';
  id: Scalars['ID'];
  label: Scalars['String'];
  size?: Maybe<FieldSize>;
  type: FieldType;
  defaultValue?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
}

export interface FieldInput {
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  size?: Maybe<FieldSize>;
  type: FieldType;
  defaultValue?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
}

export enum FieldSize {
  Half = 'HALF',
  Full = 'FULL',
}

export enum FieldType {
  Text = 'TEXT',
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Decimal = 'DECIMAL',
  Currency = 'CURRENCY',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Reference = 'REFERENCE',
  Link = 'LINK',
  File = 'FILE',
  Selection = 'SELECTION',
  Risk = 'RISK',
  Riskassessment = 'RISKASSESSMENT',
  Email = 'EMAIL',
  Richtext = 'RICHTEXT',
  Period = 'PERIOD',
  Country = 'COUNTRY',
  Language = 'LANGUAGE',
  Phonenumber = 'PHONENUMBER',
}

export interface File {
  __typename?: 'File';
  id: Scalars['ID'];
  path: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
}

export interface FilterListUsersInput {
  isAdministrator?: Maybe<Scalars['Boolean']>;
}

export type Folder = {
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  path: Array<Folder>;
  parent?: Maybe<Folder>;
  children: Array<Folder>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export interface FolderCopyInput {
  parent: Scalars['String'];
  title?: Maybe<Scalars['String']>;
}

export interface FolderCreateInput {
  title: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
}

export interface FolderDocuments extends Folder {
  __typename?: 'FolderDocuments';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  path: Array<Folder>;
  parent?: Maybe<Folder>;
  children: Array<Folder>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Document>>;
}

export interface FolderDocumentsHierarchyInput {
  where?: Maybe<FolderDocumentsHierarchyWhereInput>;
  options?: Maybe<FolderDocumentsHierarchyOptionsInput>;
}

export interface FolderDocumentsHierarchyOptionsInput {
  excludeEmptyFolders?: Maybe<Scalars['Boolean']>;
}

export interface FolderDocumentsHierarchyWhereInput {
  creatorTemplate?: Maybe<Scalars['ID']>;
}

export interface FolderTemplates extends Folder {
  __typename?: 'FolderTemplates';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  path: Array<Folder>;
  parent?: Maybe<Folder>;
  children: Array<Folder>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Template>>;
}

export enum FolderType {
  Documents = 'DOCUMENTS',
  Templates = 'TEMPLATES',
}

export interface FolderUpdateInput {
  title?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
}

export interface GitCommit {
  __typename?: 'GitCommit';
  gitCommit?: Maybe<Scalars['String']>;
}

export interface InviteDifUserRequest {
  __typename?: 'InviteDifUserRequest';
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  subscription: Scalars['String'];
  accessGroups?: Maybe<Array<AccessGroup>>;
}

export type Job = {
  id: Scalars['ID'];
  status: JobStatus;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  result?: Maybe<JobDoneResult>;
};

export interface JobDoneResult {
  __typename?: 'JobDoneResult';
  mediaObjectId?: Maybe<Scalars['ID']>;
}

export type JobResult = ArchiveDocumentAttachments;

export enum JobStatus {
  Initial = 'INITIAL',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Error = 'ERROR',
  Canceled = 'CANCELED',
  Done = 'DONE',
}

export interface ListAccessGroupsInput {
  id?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<AccessGroupType>;
}

export interface ListUsersInput {
  id?: Maybe<Array<Scalars['ID']>>;
  filter?: Maybe<FilterListUsersInput>;
}

export interface LogEntry {
  __typename?: 'LogEntry';
  id: Scalars['ID'];
  action?: Maybe<Scalars['String']>;
  objectClass?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['ID']>;
  loggedAt?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  structure: Array<Maybe<Section>>;
  updatedAt?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
}

export interface LoginResult {
  __typename?: 'LoginResult';
  token: Scalars['String'];
  user?: Maybe<User>;
}

export interface MassMessageInput {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  submissionType?: Maybe<SubmissionType>;
}

export interface MassMessageResult {
  __typename?: 'MassMessageResult';
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  submissionType?: Maybe<Scalars['String']>;
}

export interface Message {
  __typename?: 'Message';
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
}

export interface MessageInput {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
}

export enum Mode {
  Add = 'ADD',
  Override = 'OVERRIDE',
  Delete = 'DELETE',
}

export interface Module {
  __typename?: 'Module';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Module>>>;
  customerName?: Maybe<String>;
  customerId?: Maybe<String>;
  path?: Maybe<String>;
}

export interface ModuleAssociations {
  __typename?: 'ModuleAssociations';
  templates: Array<Association>;
  documents: Array<Association>;
}

export interface ModuleDocumentsInput {
  moduleIds: Array<Scalars['String']>;
  documentIds: Array<Scalars['String']>;
  folderIds: Array<Scalars['String']>;
  mode: Mode;
}

export interface ModuleTemplatesInput {
  moduleIds: Array<Scalars['String']>;
  templateIds: Array<Scalars['String']>;
  folderIds: Array<Scalars['String']>;
  mode: Mode;
}

export interface Mutation {
  __typename?: 'Mutation';
  checkoutDocument?: Maybe<DocumentDraft>;
  copyDocument?: Maybe<Document>;
  updateDocument?: Maybe<Document>;
  deleteDocument: Scalars['Boolean'];
  checkinDocumentDraft?: Maybe<Document>;
  saveDocumentDraft?: Maybe<DocumentDraft>;
  createDocumentFromTemplate?: Maybe<Document>;
  checkoutTemplate?: Maybe<TemplateDraft>;
  copyTemplate?: Maybe<Template>;
  checkinTemplateDraft?: Maybe<Template>;
  saveTemplateDraft?: Maybe<TemplateDraft>;
  deleteTemplate: Scalars['Boolean'];
  templateToDocument?: Maybe<Document>;
  /** @deprecated Field no longer supported */
  toggleUserRole?: Maybe<User>;
  /** @deprecated Field no longer supported */
  toggleUserState?: Maybe<User>;
  updateUser?: Maybe<UserResult>;
  inviteDifUser?: Maybe<InviteDifUserRequest>;
  createFolder?: Maybe<Folder>;
  copyFolder?: Maybe<Folder>;
  updateFolder?: Maybe<Folder>;
  deleteFolder?: Maybe<Scalars['Boolean']>;
  createTemplate?: Maybe<Template>;
  completeTask?: Maybe<Task>;
  uncompleteTask?: Maybe<Task>;
  uploadFile: File;
  createCustomer: Customer;
  createMessage?: Maybe<Message>;
  markReadMessage?: Maybe<Message>;
  createMassMessage?: Maybe<MassMessageResult>;
  createTask?: Maybe<Task>;
  updatePeriod?: Maybe<Period>;
  createAccessGroup?: Maybe<AccessGroup>;
  updateAccessGroup?: Maybe<AccessGroup>;
  deleteAccessGroup?: Maybe<Scalars['Boolean']>;
  createArchiveDocumentAttachments?: Maybe<ArchiveDocumentAttachments>;
  createModuleDocuments?: Maybe<Scalars['Boolean']>;
  createModuleTemplates?: Maybe<Scalars['Boolean']>;
  deleteModuleDocuments?: Maybe<Scalars['Boolean']>;
  deleteModuleTemplates?: Maybe<Scalars['Boolean']>;
}

export type Mutation_CheckoutDocumentArgs = {
  id: Scalars['ID'];
};

export type Mutation_CopyDocumentArgs = {
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input?: Maybe<CopyDocumentInput>;
};

export type Mutation_UpdateDocumentArgs = {
  id: Scalars['ID'];
  input: UpdateDocumentInput;
};

export type Mutation_DeleteDocumentArgs = {
  id: Scalars['ID'];
};

export type Mutation_CheckinDocumentDraftArgs = {
  id: Scalars['ID'];
  input?: Maybe<DocumentDraftInput>;
  checkinData: CheckinDataInput;
};

export type Mutation_SaveDocumentDraftArgs = {
  id: Scalars['ID'];
  input: DocumentDraftInput;
};

export type Mutation_CreateDocumentFromTemplateArgs = {
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input: CreateDocumentInput;
};

export type Mutation_CheckoutTemplateArgs = {
  id: Scalars['ID'];
};

export type Mutation_CopyTemplateArgs = {
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input?: Maybe<CopyTemplateInput>;
};

export type Mutation_CheckinTemplateDraftArgs = {
  id: Scalars['ID'];
  input?: Maybe<TemplateDraftInput>;
  checkinData: CheckinDataInput;
};

export type Mutation_SaveTemplateDraftArgs = {
  id: Scalars['ID'];
  input: TemplateDraftInput;
};

export type Mutation_DeleteTemplateArgs = {
  id: Scalars['ID'];
};

export type Mutation_TemplateToDocumentArgs = {
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input?: Maybe<DocumentCreateInput>;
};

export type Mutation_ToggleUserRoleArgs = {
  id: Scalars['ID'];
  role: Role;
};

export type Mutation_ToggleUserStateArgs = {
  id: Scalars['ID'];
};

export type Mutation_UpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};

export type Mutation_InviteDifUserArgs = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  subscription: Scalars['String'];
  accessGroups: Array<Scalars['String']>;
};

export type Mutation_CreateFolderArgs = {
  type: FolderType;
  input: FolderCreateInput;
};

export type Mutation_CopyFolderArgs = {
  id: Scalars['ID'];
  type: FolderType;
  input: FolderCopyInput;
};

export type Mutation_UpdateFolderArgs = {
  id: Scalars['ID'];
  type: FolderType;
  input: FolderUpdateInput;
};

export type Mutation_DeleteFolderArgs = {
  id: Scalars['ID'];
  type: FolderType;
};

export type Mutation_CreateTemplateArgs = {
  input: TemplateCreateInput;
};

export type Mutation_CompleteTaskArgs = {
  id: Scalars['ID'];
  completionComment: Scalars['String'];
};

export type Mutation_UncompleteTaskArgs = {
  id: Scalars['ID'];
};

export type Mutation_UploadFileArgs = {
  file: Scalars['Upload'];
};

export type Mutation_CreateCustomerArgs = {
  subscription: Scalars['String'];
};

export type Mutation_CreateMessageArgs = {
  input: MessageInput;
};

export type Mutation_MarkReadMessageArgs = {
  id: Scalars['ID'];
};

export type Mutation_CreateMassMessageArgs = {
  input: MassMessageInput;
};

export type Mutation_CreateTaskArgs = {
  input: CreateTaskInput;
};

export type Mutation_UpdatePeriodArgs = {
  input: UpdatePeriodInput;
};

export type Mutation_CreateAccessGroupArgs = {
  input: CreateAccessGroupInput;
};

export type Mutation_UpdateAccessGroupArgs = {
  id: Scalars['ID'];
  input: UpdateAccessGroupInput;
};

export type Mutation_DeleteAccessGroupArgs = {
  id: Scalars['ID'];
};

export type Mutation_CreateArchiveDocumentAttachmentsArgs = {
  input: ArchiveDocumentAttachmentsInput;
};

export type Mutation_CreateModuleDocumentsArgs = {
  moduleDocumentsInput: ModuleDocumentsInput;
};

export type Mutation_CreateModuleTemplatesArgs = {
  moduleTemplatesInput: ModuleTemplatesInput;
};

export type Mutation_DeleteModuleDocumentsArgs = {
  moduleDocumentsInput: ModuleDocumentsInput;
};

export type Mutation_DeleteModuleTemplatesArgs = {
  moduleTemplatesInput: ModuleTemplatesInput;
};

export interface NotFound extends PayloadException {
  __typename?: 'NotFound';
  key: Scalars['String'];
}

export type PayloadException = {
  key: Scalars['String'];
};

export interface Period {
  __typename?: 'Period';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  relatedDocument?: Maybe<Document>;
  relatedDocumentVersion?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['String']>;
  applicableInterval?: Maybe<Scalars['String']>;
  responsibles?: Maybe<Array<Maybe<Responsible>>>;
  controllers?: Maybe<Array<Maybe<Responsible>>>;
  dueAt?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  completedBy?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  completionComment?: Maybe<Scalars['String']>;
  prewarningStart?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface PeriodsDueAtInput {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  strictlyAfter?: Maybe<Scalars['String']>;
  strictlyBefore?: Maybe<Scalars['String']>;
}

export interface PeriodsInput {
  ids?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  responsibles?: Maybe<Array<Scalars['EmailAddress']>>;
  controllers?: Maybe<Array<Scalars['EmailAddress']>>;
  relatedDocument?: Maybe<Array<Scalars['String']>>;
  isRelatedDocumentDeleted: Scalars['Boolean'];
  itemsPerPage?: Maybe<Scalars['Int']>;
  dueAt?: Maybe<PeriodsDueAtInput>;
  orderDirection?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}

export interface Query {
  __typename?: 'Query';
  documents?: Maybe<Array<Document>>;
  documentsByIdList: Array<Document>;
  document?: Maybe<Document>;
  searchDocuments?: Maybe<Array<Maybe<SearchHit>>>;
  documentDraft?: Maybe<DocumentDraft>;
  breadcrumb?: Maybe<Array<Maybe<Folder>>>;
  customer?: Maybe<Customer>;
  customers: Array<Customer>;
  login?: Maybe<LoginResult>;
  user?: Maybe<User>;
  users: Array<User>;
  /** Rootfolder or specific folder */
  folder: Array<Maybe<Folder>>;
  /** @deprecated Field no longer supported */
  folderHierarchy: Scalars['JSON'];
  /** @deprecated Field no longer supported */
  folderDocumentsHierarchy: Scalars['JSON'];
  getDocumentsTree?: Maybe<DocumentsTreePayload>;
  getTemplatesTree?: Maybe<TemplatesTreePayload>;
  /**
   * Document samples hierarchy
   * @deprecated Field no longer supported
   */
  folderHierarchySamples: Scalars['JSON'];
  templates: Array<Template>;
  template?: Maybe<Template>;
  templateDraft?: Maybe<TemplateDraft>;
  logEntries: Array<LogEntry>;
  uploadedFile?: Maybe<File>;
  pagedTasks: PagedTasks;
  messages: Array<Message>;
  inboxMessages: Array<Message>;
  periods?: Maybe<Array<Period>>;
  period?: Maybe<Period>;
  accessGroups?: Maybe<Array<AccessGroup>>;
  jobs?: Maybe<Array<JobResult>>;
  archiveDocumentAttachments?: Maybe<ArchiveDocumentAttachments>;
  backendGitCommit?: Maybe<GitCommit>;
  graphQLGitCommit?: Maybe<GitCommit>;
  associatedModulesForDocument: Array<Module>;
  associatedModulesForTemplate: Array<Module>;
  moduleTree?: Maybe<Scalars['JSON']>;
  moduleAssociations: ModuleAssociations;
}

export type Query_DocumentsArgs = {
  input?: Maybe<DocumentsInput>;
};

export type Query_DocumentsByIdListArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type Query_DocumentArgs = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};

export type Query_SearchDocumentsArgs = {
  query: Scalars['String'];
  accessLevel: AccessLevel;
};

export type Query_DocumentDraftArgs = {
  id: Scalars['ID'];
};

export type Query_BreadcrumbArgs = {
  folder: Scalars['ID'];
  type: FolderType;
};

export type Query_CustomerArgs = {
  id: Scalars['ID'];
};

export type Query_LoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Query_UserArgs = {
  id: Scalars['ID'];
};

export type Query_UsersArgs = {
  input?: Maybe<ListUsersInput>;
};

export type Query_FolderArgs = {
  id?: Maybe<Scalars['ID']>;
  type: FolderType;
};

export type Query_FolderHierarchyArgs = {
  type?: Maybe<FolderType>;
};

export type Query_FolderDocumentsHierarchyArgs = {
  input?: Maybe<FolderDocumentsHierarchyInput>;
};

export type Query_GetDocumentsTreeArgs = {
  input?: Maybe<DocumentsTreeInput>;
};

export type Query_GetTemplatesTreeArgs = {
  input?: Maybe<TemplatesTreeInput>;
};

export type Query_FolderHierarchySamplesArgs = {
  type?: Maybe<FolderType>;
};

export type Query_TemplateArgs = {
  id: Scalars['ID'];
};

export type Query_TemplateDraftArgs = {
  id: Scalars['ID'];
};

export type Query_LogEntriesArgs = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

export type Query_UploadedFileArgs = {
  id: Scalars['ID'];
};

export type Query_PagedTasksArgs = {
  input?: Maybe<TaskInput>;
};

export type Query_PeriodsArgs = {
  input?: Maybe<PeriodsInput>;
};

export type Query_PeriodArgs = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};

export type Query_AccessGroupsArgs = {
  input?: Maybe<ListAccessGroupsInput>;
};

export type Query_ArchiveDocumentAttachmentsArgs = {
  id: Scalars['ID'];
};

export type Query_AssociatedModulesForDocumentArgs = {
  id: Scalars['ID'];
};

export type Query_AssociatedModulesForTemplateArgs = {
  id: Scalars['ID'];
};

export type Query_ModuleAssociationsArgs = {
  id: Scalars['ID'];
};

export interface Responsible {
  __typename?: 'Responsible';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}

export enum Role {
  RoleAdmin = 'ROLE_ADMIN',
  RoleContentadmin = 'ROLE_CONTENTADMIN',
  RoleSuperAdmin = 'ROLE_SUPER_ADMIN',
  RoleUser = 'ROLE_USER',
}

export interface SearchHit {
  __typename?: 'SearchHit';
  score: Scalars['Float'];
  memberIri: Scalars['String'];
  title: Scalars['String'];
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  highlights?: Maybe<DocumentSearchHighlight>;
}

export interface Section {
  __typename?: 'Section';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  fields: Array<Field>;
}

export enum Status {
  Open = 'open',
  Approved = 'approved',
  Deprecated = 'deprecated',
}

export interface StructureInput {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  fields: Array<FieldInput>;
}

export enum SubmissionType {
  AllCustomerUsers = 'all_customer_users',
  AllCustomerAdmins = 'all_customer_admins',
}

export interface Task {
  __typename?: 'Task';
  id: Scalars['ID'];
  title: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  relatedDocument: Document;
  relatedDocumentVersion?: Maybe<Scalars['Int']>;
  responsibleEmails?: Maybe<Array<Responsible>>;
  controllerEmails?: Maybe<Array<Controller>>;
  dueAt?: Maybe<Scalars['String']>;
  completedBy?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  completionComment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface TaskDueAtInput {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  strictlyAfter?: Maybe<Scalars['String']>;
  strictlyBefore?: Maybe<Scalars['String']>;
}

export interface TaskInput {
  controllerEmails?: Maybe<Array<Scalars['EmailAddress']>>;
  dueAt?: Maybe<TaskDueAtInput>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  relatedDocument?: Maybe<Array<Scalars['String']>>;
  responsibleEmails?: Maybe<Array<Scalars['EmailAddress']>>;
  completionComment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  relatedDocumentVersion?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  orderDirection?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}

export interface Template extends TemplateBase {
  __typename?: 'Template';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  structure: Array<Section>;
  templateCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  folder?: Maybe<Folder>;
  drafts: Array<TemplateDraft>;
  isExample?: Maybe<Scalars['Boolean']>;
  assignable?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<TemplatePermission>>;
  createdDocuments?: Maybe<Array<Document>>;
  accessLevel?: Maybe<Scalars['String']>;
}

export type TemplateBase = {
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  structure: Array<Section>;
  templateCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  folder?: Maybe<Folder>;
  isExample?: Maybe<Scalars['Boolean']>;
  assignable?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<TemplatePermission>>;
  createdDocuments?: Maybe<Array<Document>>;
};

export interface TemplateCreateInput {
  title: Scalars['String'];
  folder: Scalars['String'];
  templateCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
}

export interface TemplateDraft extends TemplateBase {
  __typename?: 'TemplateDraft';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  structure: Array<Section>;
  templateCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  folder?: Maybe<Folder>;
  template?: Maybe<Template>;
  isExample?: Maybe<Scalars['Boolean']>;
  assignable?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<TemplatePermission>>;
  createdDocuments?: Maybe<Array<Document>>;
}

export interface TemplateDraftInput {
  title: Scalars['String'];
  structure: Array<StructureInput>;
  templateCategory: DocumentCategoryType;
  referenceNumber: Scalars['String'];
  assignable?: Maybe<Scalars['Boolean']>;
}

export enum TemplatePermission {
  Get = 'GET',
  Put = 'PUT',
  CheckOut = 'CHECK_OUT',
  CheckIn = 'CHECK_IN',
  CopyTemplate = 'COPY_TEMPLATE',
  CopyTemplates = 'COPY_TEMPLATES',
  MoveTemplate = 'MOVE_TEMPLATE',
  DeleteTemplate = 'DELETE_TEMPLATE',
  ToDocument = 'TO_DOCUMENT',
}

export interface TemplatesTreeInput {
  where?: Maybe<TemplatesTreeWhereInput>;
  options?: Maybe<TemplatesTreeOptionsInput>;
}

export interface TemplatesTreeOptionsInput {
  excludeEmptyFolders?: Maybe<Scalars['Boolean']>;
}

export type TemplatesTreePayload = TemplatesTreePayloadResult | NotFound;

export interface TemplatesTreePayloadResult {
  __typename?: 'TemplatesTreePayloadResult';
  result?: Maybe<Scalars['TemplatesTree']>;
}

export interface TemplatesTreeWhereInput {
  samples?: Maybe<Scalars['Boolean']>;
}

/** error is not handled */
export interface UnhandledError {
  __typename?: 'UnhandledError';
  key: Scalars['String'];
}

export interface UpdateAccessGroupInput {
  title?: Maybe<Scalars['String']>;
  accessRights?: Maybe<Array<AccessGroupRightsInput>>;
  users?: Maybe<Array<Scalars['ID']>>;
}

export interface UpdateDocumentInput {
  folder?: Maybe<Scalars['ID']>;
  deleted?: Maybe<Scalars['Boolean']>;
}

export interface UpdatePeriodInput {
  isCompleted?: Maybe<Scalars['Boolean']>;
  completionComment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

export interface UpdateUserInput {
  role?: Maybe<Role>;
  toggleState?: Maybe<Scalars['Boolean']>;
  accessGroups?: Maybe<Array<Scalars['String']>>;
  isAdministrator?: Maybe<Scalars['Boolean']>;
}

export interface User {
  __typename?: 'User';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  accessGroups?: Maybe<Array<AccessGroup>>;
  customer?: Maybe<Customer>;
  enabled?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['String']>;
  invitationResendCount: Scalars['Int'];
}

/** user is not promoted by DIF-API */
export interface UserNotPromoted {
  __typename?: 'UserNotPromoted';
  key: Scalars['String'];
  id: Scalars['ID'];
}

export type UserResult = User | UserNotPromoted | UnhandledError;

export type CheckinDocumentDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<DocumentDraftInput>;
  checkinData: CheckinDataInput;
}>;

export type CheckinDocumentDraftMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id'>>;
};

export type CheckinTemplateDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<TemplateDraftInput>;
  checkinData: CheckinDataInput;
}>;

export type CheckinTemplateDraftMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<{ __typename?: 'Template' } & Pick<Template, 'id'>>;
};

export type SaveDocumentDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DocumentDraftInput;
}>;

export type SaveDocumentDraftMutation = { __typename?: 'Mutation' } & {
  savedDraft?: Maybe<
    { __typename?: 'DocumentDraft' } & Pick<DocumentDraft, 'id' | 'title'> & {
        contents: Array<{ __typename?: 'ContentValue' } & Pick<ContentValue, 'id' | 'value'>>;
      }
  >;
};

export type SaveTemplateDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TemplateDraftInput;
}>;

export type SaveTemplateDraftMutation = { __typename?: 'Mutation' } & {
  savedDraft?: Maybe<
    { __typename?: 'TemplateDraft' } & Pick<TemplateDraft, 'id' | 'title'> & {
        structure: Array<
          { __typename?: 'Section' } & Pick<Section, 'id' | 'title'> & {
              fields: Array<
                { __typename?: 'Field' } & Pick<Field, 'defaultValue' | 'id' | 'label' | 'size' | 'type' | 'config'>
              >;
            }
        >;
      }
  >;
};

export type ListPeriodsQueryVariables = Exact<{
  input?: Maybe<PeriodsInput>;
}>;

export interface PagedPeriods {
  __typename?: 'PagedPeriods';
  periods?: Maybe<Array<Period>>;
  totalItems?: Maybe<Scalars['Int']>;
}

export type ListPeriodsQuery = { __typename?: 'Query' } & {
  pagedPeriods?: { __typename?: 'PagedPeriods' } & Pick<PagedPeriods, 'totalItems'> & {
      periods: Maybe<Array<{ __typename?: 'Period' } & PeriodDataFragment>>;
    };
};

export type PeriodDataFragment = { __typename?: 'Period' } & Pick<
  Period,
  | 'id'
  | 'completedAt'
  | 'completedBy'
  | 'completionComment'
  | 'isCompleted'
  | 'title'
  | 'dueAt'
  | 'prewarningStart'
  | 'description'
  | 'applicableInterval'
> & {
    relatedDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id' | 'title'>>;
    controllers?: Maybe<Array<Maybe<{ __typename?: 'Controller' } & Pick<Controller, 'id' | 'name'>>>>;
    responsibles?: Maybe<Array<Maybe<{ __typename?: 'Responsible' } & Pick<Responsible, 'id' | 'name'>>>>;
  };

export type UpdatePeriodMutationVariables = Exact<{
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  completionComment?: Maybe<Scalars['String']>;
}>;

export type UpdatePeriodMutation = { __typename?: 'Mutation' } & {
  updatePeriod?: Maybe<{ __typename?: 'Period' } & PeriodDataFragment>;
};

export type DocTitleAndIdFragment = { __typename?: 'Document' } & Pick<Document, 'id' | 'title'>;

export type DocDeletedFragment = { __typename?: 'Document' } & Pick<Document, 'deleted'>;

export type OutgoingReferencesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OutgoingReferencesQuery = { __typename?: 'Query' } & {
  document?: Maybe<
    { __typename?: 'Document' } & {
      references?: Maybe<Array<{ __typename?: 'Document' } & DocTitleAndIdFragment & DocDeletedFragment>>;
    } & DocTitleAndIdFragment
  >;
};

export type IncomingReferencesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncomingReferencesQuery = { __typename?: 'Query' } & {
  document?: Maybe<
    { __typename?: 'Document' } & {
      references?: Maybe<Array<{ __typename?: 'Document' } & DocTitleAndIdFragment & DocDeletedFragment>>;
    } & DocTitleAndIdFragment
  >;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadFileMutation = { __typename?: 'Mutation' } & {
  uploadFile: { __typename?: 'File' } & Pick<File, 'filename' | 'id' | 'path' | 'mimeType'>;
};

export type UploadedFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UploadedFileQuery = { __typename?: 'Query' } & {
  uploadedFile?: Maybe<{ __typename?: 'File' } & Pick<File, 'filename' | 'path' | 'id' | 'mimeType'>>;
};

export type CheckLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type CheckLoginQuery = { __typename?: 'Query' } & {
  login?: Maybe<
    { __typename?: 'LoginResult' } & Pick<LoginResult, 'token'> & {
        user?: Maybe<
          { __typename?: 'User' } & Pick<User, 'id' | 'roles' | 'enabled' | 'email' | 'firstname' | 'lastname'> & {
              customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'id' | 'subscription' | 'isExample'>>;
            }
        >;
      }
  >;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'roles' | 'enabled' | 'email'> & {
        customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'id' | 'subscription' | 'isExample'>>;
      }
  >;
};

export type DocumentTitlesByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']>>;
}>;

export type DocumentTitlesByIdsQuery = { __typename?: 'Query' } & {
  result?: Maybe<Array<{ __typename?: 'Document' } & Pick<Document, 'id' | 'title'>>>;
};

export type GetDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
}>;

export type GetDocumentQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'Document' } & Pick<Document, 'accessLevel'> & {
        creatorTemplate?: Maybe<{ __typename?: 'Template' } & Pick<Template, 'id' | 'title'>>;
      } & DocumentData_Document_Fragment
  >;
};

export type GetDocumentDraftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDocumentDraftQuery = { __typename?: 'Query' } & {
  result?: Maybe<{ __typename?: 'DocumentDraft' } & DocumentData_DocumentDraft_Fragment>;
};

export type GetDocumentsTreeQueryVariables = Exact<{
  creatorTemplate?: Maybe<Scalars['ID']>;
  excludeEmptyFolders?: Maybe<Scalars['Boolean']>;
}>;

export type GetDocumentsTreeQuery = { __typename?: 'Query' } & {
  tree?: Maybe<
    | ({ __typename: 'DocumentsTreePayloadResult' } & Pick<DocumentsTreePayloadResult, 'result'>)
    | { __typename: 'NotFound' }
  >;
};

export type GetFolderDocumentsHierarchyQueryVariables = Exact<{
  creatorTemplate?: Maybe<Scalars['ID']>;
  excludeEmptyFolders?: Maybe<Scalars['Boolean']>;
}>;

export type GetFolderDocumentsHierarchyQuery = { __typename?: 'Query' } & Pick<Query, 'folderDocumentsHierarchy'>;

export type GetFolderTemplatesHierarchyQueryVariables = Exact<{ [key: string]: never }>;

export type GetFolderTemplatesHierarchyQuery = { __typename?: 'Query' } & Pick<Query, 'folderHierarchy'>;

export type ListDocumentsWithDataQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']>>;
}>;

export type ListDocumentsWithDataQuery = { __typename?: 'Query' } & {
  result?: Maybe<Array<{ __typename?: 'Document' } & DocumentData_Document_Fragment>>;
};

export type ListTasksQueryVariables = Exact<{
  input?: Maybe<TaskInput>;
}>;

export interface PagedTasks {
  __typename?: 'PagedTasks';
  tasks: Array<Task>;
  totalItems?: Maybe<Scalars['Int']>;
}

export type ListTasksQuery = { __typename?: 'Query' } & {
  pagedTasks: { __typename?: 'PagedTasks' } & Pick<PagedTasks, 'totalItems'> & {
      tasks: Array<{ __typename?: 'Task' } & TaskDataFragment>;
    };
};

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteDocumentMutation = { __typename?: 'Mutation' } & { isDeleted: Mutation['deleteDocument'] };

export type CopyDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input?: Maybe<CopyDocumentInput>;
}>;

export type CopyDocumentMutation = { __typename?: 'Mutation' } & {
  copiedDocument?: Maybe<
    { __typename?: 'Document' } & Pick<Document, 'id'> & {
        folder?: Maybe<
          | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>)
          | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id' | 'title'>)
        >;
      }
  >;
};

export type TemplateToDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
  folder: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
}>;

export type TemplateToDocumentMutation = { __typename?: 'Mutation' } & {
  templateToDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id' | 'slug' | 'createdAt' | 'createdBy'>>;
};

type DocumentData_Document_Fragment = { __typename: 'Document' } & Pick<
  Document,
  | 'id'
  | 'title'
  | 'createdAt'
  | 'createdBy'
  | 'documentCategory'
  | 'referenceNumber'
  | 'controller'
  | 'responsible'
  | 'responsibleFor'
  | 'status'
  | 'nextTaskAt'
  | 'taskSchedulingInterval'
  | 'taskDescription'
  | 'updatedAt'
  | 'updatedBy'
  | 'validFrom'
  | 'validUntil'
  | 'isExample'
  | 'permissions'
  | 'taskDueDatePreWarningAmount'
  | 'taskDueDatePreWarningUnit'
  | 'taskDueDatePreWarningDescription'
  | 'enablePreWarning'
> & {
    structure: Array<
      { __typename?: 'Section' } & Pick<Section, 'id' | 'title'> & {
          fields: Array<
            { __typename?: 'Field' } & Pick<Field, 'id' | 'label' | 'size' | 'type' | 'defaultValue' | 'config'>
          >;
        }
    >;
    folder?: Maybe<
      | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id'>)
      | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id'>)
    >;
    contents: Array<{ __typename?: 'ContentValue' } & Pick<ContentValue, 'id' | 'value'>>;
  };

type DocumentData_DocumentDraft_Fragment = { __typename: 'DocumentDraft' } & Pick<
  DocumentDraft,
  | 'id'
  | 'title'
  | 'createdAt'
  | 'createdBy'
  | 'documentCategory'
  | 'referenceNumber'
  | 'controller'
  | 'responsible'
  | 'responsibleFor'
  | 'status'
  | 'nextTaskAt'
  | 'taskSchedulingInterval'
  | 'taskDescription'
  | 'updatedAt'
  | 'updatedBy'
  | 'validFrom'
  | 'validUntil'
  | 'isExample'
  | 'permissions'
  | 'taskDueDatePreWarningAmount'
  | 'taskDueDatePreWarningUnit'
  | 'taskDueDatePreWarningDescription'
  | 'enablePreWarning'
> & {
    structure: Array<
      { __typename?: 'Section' } & Pick<Section, 'id' | 'title'> & {
          fields: Array<
            { __typename?: 'Field' } & Pick<Field, 'id' | 'label' | 'size' | 'type' | 'defaultValue' | 'config'>
          >;
        }
    >;
    folder?: Maybe<
      | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id'>)
      | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id'>)
    >;
    contents: Array<{ __typename?: 'ContentValue' } & Pick<ContentValue, 'id' | 'value'>>;
  };

export type DocumentDataFragment = DocumentData_Document_Fragment | DocumentData_DocumentDraft_Fragment;

export type ListRelatedTasksQueryVariables = Exact<{
  document: Scalars['ID'];
  page: Scalars['Int'];
}>;

export type ListRelatedTasksQuery = { __typename?: 'Query' } & {
  document?: Maybe<
    { __typename?: 'Document' } & Pick<Document, 'id' | 'taskSchedulingInterval'> & {
        relatedTasks?: Maybe<PagedTasks>;
      }
  >;
};

export type TaskDataFragment = { __typename?: 'Task' } & Pick<
  Task,
  'id' | 'completedAt' | 'completedBy' | 'isCompleted' | 'title' | 'dueAt' | 'completionComment' | 'description'
> & {
    responsibleEmails?: Maybe<Array<{ __typename?: 'Responsible' } & Pick<Responsible, 'id' | 'email' | 'name'>>>;
    controllerEmails?: Maybe<Array<{ __typename?: 'Controller' } & Pick<Controller, 'id' | 'email' | 'name'>>>;
    relatedDocument: { __typename?: 'Document' } & Pick<Document, 'id' | 'taskSchedulingInterval'>;
  };

export type CreateArchiveDocumentAttachmentsMutationVariables = Exact<{
  input: ArchiveDocumentAttachmentsInput;
}>;

export type CreateArchiveDocumentAttachmentsMutation = { __typename?: 'Mutation' } & {
  createArchiveDocumentAttachments?: Maybe<
    { __typename?: 'ArchiveDocumentAttachments' } & ArchiveDocumentAttachmentsDataFragment
  >;
};

export type GetArchiveDocumentAttachmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetArchiveDocumentAttachmentsQuery = { __typename?: 'Query' } & {
  archiveDocumentAttachments?: Maybe<
    { __typename?: 'ArchiveDocumentAttachments' } & ArchiveDocumentAttachmentsDataFragment
  >;
};

export type ArchiveDocumentAttachmentsDataFragment = { __typename?: 'ArchiveDocumentAttachments' } & Pick<
  ArchiveDocumentAttachments,
  'id' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'additionalMediaObjects'
> & { result?: Maybe<{ __typename?: 'JobDoneResult' } & Pick<JobDoneResult, 'mediaObjectId'>> };

export type CreateDocumentFromTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input: CreateDocumentInput;
}>;

export type CreateDocumentFromTemplateMutation = { __typename?: 'Mutation' } & {
  createDocumentFromTemplate?: Maybe<
    { __typename?: 'Document' } & Pick<Document, 'id' | 'title' | 'slug' | 'createdAt' | 'createdBy'>
  >;
};

export type ListTemplatesForSelectionQueryVariables = Exact<{ [key: string]: never }>;

export type ListTemplatesForSelectionQuery = { __typename?: 'Query' } & {
  templates: Array<{ __typename?: 'Template' } & { value: Template['id']; label: Template['title'] }>;
};

export type GetBackendGitCommitQueryVariables = Exact<{ [key: string]: never }>;

export type GetBackendGitCommitQuery = { __typename?: 'Query' } & {
  backendGitCommit?: Maybe<{ __typename?: 'GitCommit' } & Pick<GitCommit, 'gitCommit'>>;
};

export type GetGraphQlGitCommitQueryVariables = Exact<{ [key: string]: never }>;

export type GetGraphQlGitCommitQuery = { __typename?: 'Query' } & {
  graphQLGitCommit?: Maybe<{ __typename?: 'GitCommit' } & Pick<GitCommit, 'gitCommit'>>;
};

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTemplateMutation = { __typename?: 'Mutation' } & { isDeleted: Mutation['deleteTemplate'] };

export type CopyTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  folder: Scalars['ID'];
  input?: Maybe<CopyTemplateInput>;
}>;

export type CopyTemplateMutation = { __typename?: 'Mutation' } & {
  copiedTemplate?: Maybe<
    { __typename?: 'Template' } & Pick<Template, 'id'> & {
        folder?: Maybe<
          | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>)
          | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id' | 'title'>)
        >;
      }
  >;
};

export type InviteNewUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  subscription: Scalars['String'];
  accessGroups: Array<Scalars['String']>;
}>;

export type InviteNewUserMutation = { __typename?: 'Mutation' } & {
  inviteDifUser?: Maybe<{ __typename?: 'InviteDifUserRequest' } & Pick<InviteDifUserRequest, 'email'>>;
};

export type CreateModuleDocumentsMutationVariables = Exact<{
  moduleDocumentsInput: ModuleDocumentsInput;
}>;

export type CreateModuleDocumentsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createModuleDocuments'>;

export type CreateModuleTemplatesMutationVariables = Exact<{
  moduleTemplatesInput: ModuleTemplatesInput;
}>;

export type CreateModuleTemplatesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createModuleTemplates'>;

export type DeleteModuleDocumentsMutationVariables = Exact<{
  moduleDocumentsInput: ModuleDocumentsInput;
}>;

export type DeleteModuleDocumentsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteModuleDocuments'>;

export type DeleteModuleTemplatesMutationVariables = Exact<{
  moduleTemplatesInput: ModuleTemplatesInput;
}>;

export type DeleteModuleTemplatesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteModuleTemplates'>;

export type AssociatedModulesForDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AssociatedModulesForDocumentQuery = { __typename?: 'Query' } & {
  associatedModulesForDocument: Array<{ __typename?: 'Module' } & Pick<Module, 'id' | 'name' | 'description'>>;
};

export type ModuleAssociationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ModuleAssociationsQuery = { __typename?: 'Query' } & {
  moduleAssociations: { __typename?: 'ModuleAssociations' } & {
    templates: Array<
      { __typename?: 'Association' } & Pick<
        Association,
        'id' | 'title' | 'creatorTemplateId' | 'creatorTemplateTitle' | 'customerName' | 'customerId' | 'path'
      >
    >;
    documents: Array<
      { __typename?: 'Association' } & Pick<
        Association,
        'id' | 'title' | 'creatorTemplateId' | 'creatorTemplateTitle' | 'customerName' | 'customerId' | 'path'
      >
    >;
  };
};

export type GetModuleTreeQueryVariables = Exact<{ [key: string]: never }>;

export type GetModuleTreeQuery = { __typename?: 'Query' } & Pick<Query, 'moduleTree'>;

export type AssociatedModulesForTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AssociatedModulesForTemplateQuery = { __typename?: 'Query' } & {
  associatedModulesForTemplate: Array<{ __typename?: 'Module' } & Pick<Module, 'id' | 'name' | 'description'>>;
};

export type CreateAccessGroupMutationVariables = Exact<{
  input: CreateAccessGroupInput;
}>;

export type CreateAccessGroupMutation = { __typename?: 'Mutation' } & {
  createAccessGroup?: Maybe<{ __typename?: 'AccessGroup' } & Pick<AccessGroup, 'id'>>;
};

export type DeleteAccessGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAccessGroupMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteAccessGroup'>;

export type ListAccessGroupsQueryVariables = Exact<{
  id?: Maybe<Array<Scalars['ID']>>;
}>;

export type ListAccessGroupsQuery = { __typename?: 'Query' } & {
  accessGroups?: Maybe<
    Array<
      { __typename?: 'AccessGroup' } & Pick<AccessGroup, 'id' | 'title'> & {
          users?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'firstname' | 'lastname' | 'email'>>>;
          accessRights?: Maybe<
            Array<{ __typename?: 'AccessGroupRights' } & Pick<AccessGroupRights, 'id' | 'type' | 'subject' | 'access'>>
          >;
        }
    >
  >;
};

export type UpdateAccessGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateAccessGroupInput;
}>;

export type UpdateAccessGroupMutation = { __typename?: 'Mutation' } & {
  updateAccessGroup?: Maybe<{ __typename?: 'AccessGroup' } & Pick<AccessGroup, 'id'>>;
};

export type CopyFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  type: FolderType;
  input: FolderCopyInput;
}>;

export type CopyFolderMutation = { __typename?: 'Mutation' } & {
  createdFolder?: Maybe<
    | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>)
    | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id' | 'title'>)
  >;
};

export type CreateFolderMutationVariables = Exact<{
  type: FolderType;
  input: FolderCreateInput;
}>;

export type CreateFolderMutation = { __typename?: 'Mutation' } & {
  createdFolder?: Maybe<
    | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>)
    | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id' | 'title'>)
  >;
};

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  type: FolderType;
}>;

export type DeleteFolderMutation = { __typename?: 'Mutation' } & { deletedFolder: Mutation['deleteFolder'] };

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  type: FolderType;
  input: FolderUpdateInput;
}>;

export type UpdateFolderMutation = { __typename?: 'Mutation' } & {
  updatedFolder?: Maybe<
    | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>)
    | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id' | 'title'>)
  >;
};

export type GetFolderTreeQueryVariables = Exact<{
  type?: Maybe<FolderType>;
}>;

export type GetFolderTreeQuery = { __typename?: 'Query' } & { folderTree: Query['folderHierarchy'] };

export type GetTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTemplateQuery = { __typename?: 'Query' } & {
  result?: Maybe<{ __typename?: 'Template' } & Pick<Template, 'accessLevel'> & TemplateData_Template_Fragment>;
};

type TemplateData_Template_Fragment = { __typename: 'Template' } & Pick<
  Template,
  'id' | 'title' | 'templateCategory' | 'referenceNumber' | 'assignable' | 'isExample' | 'permissions'
> & {
    structure: Array<
      { __typename?: 'Section' } & Pick<Section, 'id' | 'title'> & {
          fields: Array<
            { __typename?: 'Field' } & Pick<Field, 'id' | 'label' | 'size' | 'type' | 'defaultValue' | 'config'>
          >;
        }
    >;
    folder?: Maybe<
      | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id'>)
      | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id'>)
    >;
    createdDocuments?: Maybe<
      Array<{ __typename?: 'Document' } & Pick<Document, 'id' | 'title'> & DocumentPath_Document_Fragment>
    >;
  };

type TemplateData_TemplateDraft_Fragment = { __typename: 'TemplateDraft' } & Pick<
  TemplateDraft,
  'id' | 'title' | 'templateCategory' | 'referenceNumber' | 'assignable' | 'isExample' | 'permissions'
> & {
    structure: Array<
      { __typename?: 'Section' } & Pick<Section, 'id' | 'title'> & {
          fields: Array<
            { __typename?: 'Field' } & Pick<Field, 'id' | 'label' | 'size' | 'type' | 'defaultValue' | 'config'>
          >;
        }
    >;
    folder?: Maybe<
      | ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id'>)
      | ({ __typename?: 'FolderTemplates' } & Pick<FolderTemplates, 'id'>)
    >;
    createdDocuments?: Maybe<
      Array<{ __typename?: 'Document' } & Pick<Document, 'id' | 'title'> & DocumentPath_Document_Fragment>
    >;
  };

export type TemplateDataFragment = TemplateData_Template_Fragment | TemplateData_TemplateDraft_Fragment;

export type CreateTemplateMutationVariables = Exact<{
  title: Scalars['String'];
  folder: Scalars['String'];
  referenceNumber: Scalars['String'];
  templateCategory: DocumentCategoryType;
}>;

export type CreateTemplateMutation = { __typename?: 'Mutation' } & {
  createdTemplate?: Maybe<{ __typename?: 'Template' } & Pick<Template, 'id' | 'createdAt' | 'createdBy'>>;
};

export type GetTemplatesTreeQueryVariables = Exact<{ [key: string]: never }>;

export type GetTemplatesTreeQuery = { __typename?: 'Query' } & {
  templatesTree?: Maybe<
    | ({ __typename?: 'TemplatesTreePayloadResult' } & Pick<TemplatesTreePayloadResult, 'result'>)
    | { __typename?: 'NotFound' }
  >;
};

export type GetTemplateDraftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTemplateDraftQuery = { __typename?: 'Query' } & {
  result?: Maybe<{ __typename?: 'TemplateDraft' } & TemplateData_TemplateDraft_Fragment>;
};

type DocumentPath_Document_Fragment = { __typename?: 'Document' } & {
  path?: Maybe<
    Array<
      ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>) | { __typename?: 'FolderTemplates' }
    >
  >;
};

type DocumentPath_DocumentDraft_Fragment = { __typename?: 'DocumentDraft' } & {
  path?: Maybe<
    Array<
      ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>) | { __typename?: 'FolderTemplates' }
    >
  >;
};

export type DocumentPathFragment = DocumentPath_Document_Fragment | DocumentPath_DocumentDraft_Fragment;

type DocumentFolder_Document_Fragment = { __typename?: 'Document' } & {
  folder?: Maybe<
    | ({ __typename?: 'FolderDocuments' } & FolderPath_FolderDocuments_Fragment)
    | ({ __typename?: 'FolderTemplates' } & FolderPath_FolderTemplates_Fragment)
  >;
};

type DocumentFolder_DocumentDraft_Fragment = { __typename?: 'DocumentDraft' } & {
  folder?: Maybe<
    | ({ __typename?: 'FolderDocuments' } & FolderPath_FolderDocuments_Fragment)
    | ({ __typename?: 'FolderTemplates' } & FolderPath_FolderTemplates_Fragment)
  >;
};

export type DocumentFolderFragment = DocumentFolder_Document_Fragment | DocumentFolder_DocumentDraft_Fragment;

type FolderPath_FolderDocuments_Fragment = { __typename?: 'FolderDocuments' } & {
  path: Array<
    ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>) | { __typename?: 'FolderTemplates' }
  >;
};

type FolderPath_FolderTemplates_Fragment = { __typename?: 'FolderTemplates' } & {
  path: Array<
    ({ __typename?: 'FolderDocuments' } & Pick<FolderDocuments, 'id' | 'title'>) | { __typename?: 'FolderTemplates' }
  >;
};

export type FolderPathFragment = FolderPath_FolderDocuments_Fragment | FolderPath_FolderTemplates_Fragment;

export type ListDocumentsQueryVariables = Exact<{
  input?: Maybe<DocumentsInput>;
}>;

export type ListDocumentsQuery = { __typename?: 'Query' } & {
  documents?: Maybe<
    Array<
      { __typename?: 'Document' } & Pick<Document, 'id' | 'title' | 'updatedAt'> &
        DocumentPath_Document_Fragment &
        DocumentFolder_Document_Fragment
    >
  >;
};

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserInfoQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'firstname'
      | 'lastname'
      | 'username'
      | 'email'
      | 'roles'
      | 'enabled'
      | 'lastLogin'
      | 'invitationResendCount'
    > & {
        accessGroups?: Maybe<Array<{ __typename?: 'AccessGroup' } & Pick<AccessGroup, 'id' | 'title'>>>;
        customer?: Maybe<{ __typename?: 'Customer' } & Pick<Customer, 'id' | 'subscription' | 'corporation'>>;
      }
  >;
};

export type GetUsersQueryVariables = Exact<{
  input?: Maybe<ListUsersInput>;
}>;

export type GetUsersQuery = { __typename?: 'Query' } & {
  users: Array<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'firstname'
      | 'lastname'
      | 'username'
      | 'email'
      | 'roles'
      | 'enabled'
      | 'lastLogin'
      | 'invitationResendCount'
    > & { accessGroups?: Maybe<Array<{ __typename?: 'AccessGroup' } & Pick<AccessGroup, 'id' | 'title'>>> }
  >;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updatedUser?: Maybe<
    | ({ __typename: 'User' } & Pick<User, 'id' | 'roles' | 'enabled'>)
    | ({ __typename: 'UserNotPromoted' } & Pick<UserNotPromoted, 'key' | 'id'>)
    | { __typename: 'UnhandledError' }
  >;
};

export const PeriodDataFragmentDoc = gql`
  fragment PeriodData on Period {
    id
    completedAt
    completedBy
    completionComment
    isCompleted
    title
    dueAt
    prewarningStart
    description
    relatedDocument {
      id
      title
    }
    responsibles {
      id
      name
    }
    applicableInterval
  }
`;
export const DocTitleAndIdFragmentDoc = gql`
  fragment docTitleAndId on Document {
    id
    title
  }
`;
export const DocDeletedFragmentDoc = gql`
  fragment docDeleted on Document {
    deleted
  }
`;
export const DocumentDataFragmentDoc = gql`
  fragment DocumentData on DocumentBase {
    __typename
    id
    title
    structure {
      id
      title
      fields {
        id
        label
        size
        type
        defaultValue
        config
      }
    }
    folder {
      id
    }
    contents {
      id
      value
    }
    createdAt
    createdBy
    documentCategory
    referenceNumber
    controller
    responsible
    responsibleFor
    status
    nextTaskAt
    taskSchedulingInterval
    taskDescription
    updatedAt
    updatedBy
    validFrom
    validUntil
    isExample
    permissions
    taskDueDatePreWarningAmount
    taskDueDatePreWarningUnit
    taskDueDatePreWarningDescription
    enablePreWarning
  }
`;
export const TaskDataFragmentDoc = gql`
  fragment TaskData on Task {
    id
    completedAt
    completedBy
    isCompleted
    title
    dueAt
    completionComment
    description
    responsibleEmails {
      id
      email
      name
    }
    controllerEmails {
      id
      email
      name
    }
    relatedDocument {
      id
      taskSchedulingInterval
    }
  }
`;
export const ArchiveDocumentAttachmentsDataFragmentDoc = gql`
  fragment ArchiveDocumentAttachmentsData on ArchiveDocumentAttachments {
    id
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    additionalMediaObjects
    result {
      mediaObjectId
    }
  }
`;
export const DocumentPathFragmentDoc = gql`
  fragment DocumentPath on DocumentBase {
    path {
      ... on FolderDocuments {
        id
        title
      }
    }
  }
`;
export const TemplateDataFragmentDoc = gql`
  fragment TemplateData on TemplateBase {
    __typename
    id
    title
    templateCategory
    referenceNumber
    assignable
    structure {
      id
      title
      fields {
        id
        label
        size
        type
        defaultValue
        config
      }
    }
    folder {
      id
    }
    isExample
    createdDocuments {
      id
      title
      ...DocumentPath
    }
    permissions
  }
  ${DocumentPathFragmentDoc}
`;
export const FolderPathFragmentDoc = gql`
  fragment FolderPath on Folder {
    path {
      ... on FolderDocuments {
        id
        title
      }
    }
  }
`;
export const DocumentFolderFragmentDoc = gql`
  fragment DocumentFolder on DocumentBase {
    folder {
      ...FolderPath
    }
  }
  ${FolderPathFragmentDoc}
`;
export const CheckinDocumentDraftDocument = gql`
  mutation CheckinDocumentDraft($id: ID!, $input: DocumentDraftInput, $checkinData: CheckinDataInput!) {
    result: checkinDocumentDraft(id: $id, input: $input, checkinData: $checkinData) {
      id
    }
  }
`;
export type CheckinDocumentDraftMutationFn = ApolloReactCommon.MutationFunction<
  CheckinDocumentDraftMutation,
  CheckinDocumentDraftMutationVariables
>;

/**
 * __useCheckinDocumentDraftMutation__
 *
 * To run a mutation, you first call `useCheckinDocumentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinDocumentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinDocumentDraftMutation, { data, loading, error }] = useCheckinDocumentDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      checkinData: // value for 'checkinData'
 *   },
 * });
 */
export function useCheckinDocumentDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckinDocumentDraftMutation,
    CheckinDocumentDraftMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CheckinDocumentDraftMutation, CheckinDocumentDraftMutationVariables>(
    CheckinDocumentDraftDocument,
    baseOptions
  );
}
export type CheckinDocumentDraftMutationHookResult = ReturnType<typeof useCheckinDocumentDraftMutation>;
export type CheckinDocumentDraftMutationResult = ApolloReactCommon.MutationResult<CheckinDocumentDraftMutation>;
export type CheckinDocumentDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CheckinDocumentDraftMutation,
  CheckinDocumentDraftMutationVariables
>;
export const CheckinTemplateDraftDocument = gql`
  mutation CheckinTemplateDraft($id: ID!, $input: TemplateDraftInput, $checkinData: CheckinDataInput!) {
    result: checkinTemplateDraft(id: $id, input: $input, checkinData: $checkinData) {
      id
    }
  }
`;
export type CheckinTemplateDraftMutationFn = ApolloReactCommon.MutationFunction<
  CheckinTemplateDraftMutation,
  CheckinTemplateDraftMutationVariables
>;

/**
 * __useCheckinTemplateDraftMutation__
 *
 * To run a mutation, you first call `useCheckinTemplateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinTemplateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinTemplateDraftMutation, { data, loading, error }] = useCheckinTemplateDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      checkinData: // value for 'checkinData'
 *   },
 * });
 */
export function useCheckinTemplateDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckinTemplateDraftMutation,
    CheckinTemplateDraftMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CheckinTemplateDraftMutation, CheckinTemplateDraftMutationVariables>(
    CheckinTemplateDraftDocument,
    baseOptions
  );
}
export type CheckinTemplateDraftMutationHookResult = ReturnType<typeof useCheckinTemplateDraftMutation>;
export type CheckinTemplateDraftMutationResult = ApolloReactCommon.MutationResult<CheckinTemplateDraftMutation>;
export type CheckinTemplateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CheckinTemplateDraftMutation,
  CheckinTemplateDraftMutationVariables
>;
export const SaveDocumentDraftDocument = gql`
  mutation SaveDocumentDraft($id: ID!, $input: DocumentDraftInput!) {
    savedDraft: saveDocumentDraft(id: $id, input: $input) {
      id
      title
      contents {
        id
        value
      }
    }
  }
`;
export type SaveDocumentDraftMutationFn = ApolloReactCommon.MutationFunction<
  SaveDocumentDraftMutation,
  SaveDocumentDraftMutationVariables
>;

/**
 * __useSaveDocumentDraftMutation__
 *
 * To run a mutation, you first call `useSaveDocumentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentDraftMutation, { data, loading, error }] = useSaveDocumentDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDocumentDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveDocumentDraftMutation, SaveDocumentDraftMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveDocumentDraftMutation, SaveDocumentDraftMutationVariables>(
    SaveDocumentDraftDocument,
    baseOptions
  );
}
export type SaveDocumentDraftMutationHookResult = ReturnType<typeof useSaveDocumentDraftMutation>;
export type SaveDocumentDraftMutationResult = ApolloReactCommon.MutationResult<SaveDocumentDraftMutation>;
export type SaveDocumentDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveDocumentDraftMutation,
  SaveDocumentDraftMutationVariables
>;
export const SaveTemplateDraftDocument = gql`
  mutation SaveTemplateDraft($id: ID!, $input: TemplateDraftInput!) {
    savedDraft: saveTemplateDraft(id: $id, input: $input) {
      id
      title
      structure {
        id
        title
        fields {
          defaultValue
          id
          label
          size
          type
          config
        }
      }
    }
  }
`;
export type SaveTemplateDraftMutationFn = ApolloReactCommon.MutationFunction<
  SaveTemplateDraftMutation,
  SaveTemplateDraftMutationVariables
>;

/**
 * __useSaveTemplateDraftMutation__
 *
 * To run a mutation, you first call `useSaveTemplateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTemplateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTemplateDraftMutation, { data, loading, error }] = useSaveTemplateDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTemplateDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveTemplateDraftMutation, SaveTemplateDraftMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveTemplateDraftMutation, SaveTemplateDraftMutationVariables>(
    SaveTemplateDraftDocument,
    baseOptions
  );
}
export type SaveTemplateDraftMutationHookResult = ReturnType<typeof useSaveTemplateDraftMutation>;
export type SaveTemplateDraftMutationResult = ApolloReactCommon.MutationResult<SaveTemplateDraftMutation>;
export type SaveTemplateDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTemplateDraftMutation,
  SaveTemplateDraftMutationVariables
>;
export const ListPeriodsDocument = gql`
  query ListPeriods($input: PeriodsInput) {
    pagedPeriods(input: $input) {
      periods {
        ...PeriodData
      }
      totalItems
    }
  }
  ${PeriodDataFragmentDoc}
`;

/**
 * __useListPeriodsQuery__
 *
 * To run a query within a React component, call `useListPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPeriodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPeriodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListPeriodsQuery, ListPeriodsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListPeriodsQuery, ListPeriodsQueryVariables>(ListPeriodsDocument, baseOptions);
}
export function useListPeriodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPeriodsQuery, ListPeriodsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListPeriodsQuery, ListPeriodsQueryVariables>(ListPeriodsDocument, baseOptions);
}
export type ListPeriodsQueryHookResult = ReturnType<typeof useListPeriodsQuery>;
export type ListPeriodsLazyQueryHookResult = ReturnType<typeof useListPeriodsLazyQuery>;
export type ListPeriodsQueryResult = ApolloReactCommon.QueryResult<ListPeriodsQuery, ListPeriodsQueryVariables>;
export function refetchListPeriodsQuery(variables?: ListPeriodsQueryVariables) {
  return { query: ListPeriodsDocument, variables: variables };
}
export const UpdatePeriodDocument = gql`
  mutation UpdatePeriod($id: ID!, $isCompleted: Boolean, $completionComment: String) {
    updatePeriod(input: { id: $id, isCompleted: $isCompleted, completionComment: $completionComment }) {
      ...PeriodData
    }
  }
  ${PeriodDataFragmentDoc}
`;
export type UpdatePeriodMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePeriodMutation,
  UpdatePeriodMutationVariables
>;

/**
 * __useUpdatePeriodMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodMutation, { data, loading, error }] = useUpdatePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isCompleted: // value for 'isCompleted'
 *      completionComment: // value for 'completionComment'
 *   },
 * });
 */
export function useUpdatePeriodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePeriodMutation, UpdatePeriodMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePeriodMutation, UpdatePeriodMutationVariables>(
    UpdatePeriodDocument,
    baseOptions
  );
}
export type UpdatePeriodMutationHookResult = ReturnType<typeof useUpdatePeriodMutation>;
export type UpdatePeriodMutationResult = ApolloReactCommon.MutationResult<UpdatePeriodMutation>;
export type UpdatePeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePeriodMutation,
  UpdatePeriodMutationVariables
>;
export const OutgoingReferencesDocument = gql`
  query OutgoingReferences($id: ID!) {
    document(id: $id) {
      ...docTitleAndId
      references: outgoingReferences {
        ...docTitleAndId
        ...docDeleted
      }
    }
  }
  ${DocTitleAndIdFragmentDoc}
  ${DocDeletedFragmentDoc}
`;

/**
 * __useOutgoingReferencesQuery__
 *
 * To run a query within a React component, call `useOutgoingReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingReferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutgoingReferencesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OutgoingReferencesQuery, OutgoingReferencesQueryVariables>
) {
  return ApolloReactHooks.useQuery<OutgoingReferencesQuery, OutgoingReferencesQueryVariables>(
    OutgoingReferencesDocument,
    baseOptions
  );
}
export function useOutgoingReferencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OutgoingReferencesQuery, OutgoingReferencesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OutgoingReferencesQuery, OutgoingReferencesQueryVariables>(
    OutgoingReferencesDocument,
    baseOptions
  );
}
export type OutgoingReferencesQueryHookResult = ReturnType<typeof useOutgoingReferencesQuery>;
export type OutgoingReferencesLazyQueryHookResult = ReturnType<typeof useOutgoingReferencesLazyQuery>;
export type OutgoingReferencesQueryResult = ApolloReactCommon.QueryResult<
  OutgoingReferencesQuery,
  OutgoingReferencesQueryVariables
>;
export function refetchOutgoingReferencesQuery(variables?: OutgoingReferencesQueryVariables) {
  return { query: OutgoingReferencesDocument, variables: variables };
}
export const IncomingReferencesDocument = gql`
  query IncomingReferences($id: ID!) {
    document(id: $id) {
      ...docTitleAndId
      references: incomingReferences {
        ...docTitleAndId
        ...docDeleted
      }
    }
  }
  ${DocTitleAndIdFragmentDoc}
  ${DocDeletedFragmentDoc}
`;

/**
 * __useIncomingReferencesQuery__
 *
 * To run a query within a React component, call `useIncomingReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingReferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncomingReferencesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IncomingReferencesQuery, IncomingReferencesQueryVariables>
) {
  return ApolloReactHooks.useQuery<IncomingReferencesQuery, IncomingReferencesQueryVariables>(
    IncomingReferencesDocument,
    baseOptions
  );
}
export function useIncomingReferencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncomingReferencesQuery, IncomingReferencesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<IncomingReferencesQuery, IncomingReferencesQueryVariables>(
    IncomingReferencesDocument,
    baseOptions
  );
}
export type IncomingReferencesQueryHookResult = ReturnType<typeof useIncomingReferencesQuery>;
export type IncomingReferencesLazyQueryHookResult = ReturnType<typeof useIncomingReferencesLazyQuery>;
export type IncomingReferencesQueryResult = ApolloReactCommon.QueryResult<
  IncomingReferencesQuery,
  IncomingReferencesQueryVariables
>;
export function refetchIncomingReferencesQuery(variables?: IncomingReferencesQueryVariables) {
  return { query: IncomingReferencesDocument, variables: variables };
}
export const UploadFileDocument = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      id
      path
      mimeType
    }
  }
`;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>
) {
  return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
}
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const UploadedFileDocument = gql`
  query UploadedFile($id: ID!) {
    uploadedFile(id: $id) {
      filename
      path
      id
      mimeType
    }
  }
`;

/**
 * __useUploadedFileQuery__
 *
 * To run a query within a React component, call `useUploadedFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadedFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadedFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadedFileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UploadedFileQuery, UploadedFileQueryVariables>
) {
  return ApolloReactHooks.useQuery<UploadedFileQuery, UploadedFileQueryVariables>(UploadedFileDocument, baseOptions);
}
export function useUploadedFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UploadedFileQuery, UploadedFileQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UploadedFileQuery, UploadedFileQueryVariables>(
    UploadedFileDocument,
    baseOptions
  );
}
export type UploadedFileQueryHookResult = ReturnType<typeof useUploadedFileQuery>;
export type UploadedFileLazyQueryHookResult = ReturnType<typeof useUploadedFileLazyQuery>;
export type UploadedFileQueryResult = ApolloReactCommon.QueryResult<UploadedFileQuery, UploadedFileQueryVariables>;
export function refetchUploadedFileQuery(variables?: UploadedFileQueryVariables) {
  return { query: UploadedFileDocument, variables: variables };
}
export const CheckLoginDocument = gql`
  query CheckLogin($username: String!, $password: String!) {
    login(email: $username, password: $password) {
      token
      user {
        id
        roles
        enabled
        email
        firstname
        lastname
        customer {
          id
          subscription
          isExample
        }
      }
    }
  }
`;

/**
 * __useCheckLoginQuery__
 *
 * To run a query within a React component, call `useCheckLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLoginQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckLoginQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CheckLoginQuery, CheckLoginQueryVariables>
) {
  return ApolloReactHooks.useQuery<CheckLoginQuery, CheckLoginQueryVariables>(CheckLoginDocument, baseOptions);
}
export function useCheckLoginLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckLoginQuery, CheckLoginQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CheckLoginQuery, CheckLoginQueryVariables>(CheckLoginDocument, baseOptions);
}
export type CheckLoginQueryHookResult = ReturnType<typeof useCheckLoginQuery>;
export type CheckLoginLazyQueryHookResult = ReturnType<typeof useCheckLoginLazyQuery>;
export type CheckLoginQueryResult = ApolloReactCommon.QueryResult<CheckLoginQuery, CheckLoginQueryVariables>;
export function refetchCheckLoginQuery(variables?: CheckLoginQueryVariables) {
  return { query: CheckLoginDocument, variables: variables };
}
export const GetUserDocument = gql`
  query GetUser($userId: ID!) {
    user(id: $userId) {
      id
      roles
      enabled
      email
      customer {
        id
        subscription
        isExample
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
  return { query: GetUserDocument, variables: variables };
}
export const DocumentTitlesByIdsDocument = gql`
  query DocumentTitlesByIds($ids: [ID!]) {
    result: documents(input: { ids: $ids }) {
      id
      title
    }
  }
`;

/**
 * __useDocumentTitlesByIdsQuery__
 *
 * To run a query within a React component, call `useDocumentTitlesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTitlesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTitlesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDocumentTitlesByIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentTitlesByIdsQuery, DocumentTitlesByIdsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DocumentTitlesByIdsQuery, DocumentTitlesByIdsQueryVariables>(
    DocumentTitlesByIdsDocument,
    baseOptions
  );
}
export function useDocumentTitlesByIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentTitlesByIdsQuery, DocumentTitlesByIdsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DocumentTitlesByIdsQuery, DocumentTitlesByIdsQueryVariables>(
    DocumentTitlesByIdsDocument,
    baseOptions
  );
}
export type DocumentTitlesByIdsQueryHookResult = ReturnType<typeof useDocumentTitlesByIdsQuery>;
export type DocumentTitlesByIdsLazyQueryHookResult = ReturnType<typeof useDocumentTitlesByIdsLazyQuery>;
export type DocumentTitlesByIdsQueryResult = ApolloReactCommon.QueryResult<
  DocumentTitlesByIdsQuery,
  DocumentTitlesByIdsQueryVariables
>;
export function refetchDocumentTitlesByIdsQuery(variables?: DocumentTitlesByIdsQueryVariables) {
  return { query: DocumentTitlesByIdsDocument, variables: variables };
}
export const GetDocumentDocument = gql`
  query GetDocument($id: ID!, $version: Int) {
    result: document(id: $id, version: $version) {
      creatorTemplate {
        id
        title
      }
      accessLevel
      ...DocumentData
    }
  }
  ${DocumentDataFragmentDoc}
`;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetDocumentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
}
export function useGetDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
}
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = ApolloReactCommon.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export function refetchGetDocumentQuery(variables?: GetDocumentQueryVariables) {
  return { query: GetDocumentDocument, variables: variables };
}
export const GetDocumentDraftDocument = gql`
  query GetDocumentDraft($id: ID!) {
    result: documentDraft(id: $id) {
      ...DocumentData
    }
  }
  ${DocumentDataFragmentDoc}
`;

/**
 * __useGetDocumentDraftQuery__
 *
 * To run a query within a React component, call `useGetDocumentDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentDraftQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentDraftQuery, GetDocumentDraftQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetDocumentDraftQuery, GetDocumentDraftQueryVariables>(
    GetDocumentDraftDocument,
    baseOptions
  );
}
export function useGetDocumentDraftLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentDraftQuery, GetDocumentDraftQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDocumentDraftQuery, GetDocumentDraftQueryVariables>(
    GetDocumentDraftDocument,
    baseOptions
  );
}
export type GetDocumentDraftQueryHookResult = ReturnType<typeof useGetDocumentDraftQuery>;
export type GetDocumentDraftLazyQueryHookResult = ReturnType<typeof useGetDocumentDraftLazyQuery>;
export type GetDocumentDraftQueryResult = ApolloReactCommon.QueryResult<
  GetDocumentDraftQuery,
  GetDocumentDraftQueryVariables
>;
export function refetchGetDocumentDraftQuery(variables?: GetDocumentDraftQueryVariables) {
  return { query: GetDocumentDraftDocument, variables: variables };
}
export const GetDocumentsTreeDocument = gql`
  query GetDocumentsTree($creatorTemplate: ID, $excludeEmptyFolders: Boolean) {
    tree: getDocumentsTree(
      input: { where: { creatorTemplate: $creatorTemplate }, options: { excludeEmptyFolders: $excludeEmptyFolders } }
    ) {
      __typename
      ... on DocumentsTreePayloadResult {
        result
      }
      ... on PayloadException {
        __typename
      }
    }
  }
`;

/**
 * __useGetDocumentsTreeQuery__
 *
 * To run a query within a React component, call `useGetDocumentsTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsTreeQuery({
 *   variables: {
 *      creatorTemplate: // value for 'creatorTemplate'
 *      excludeEmptyFolders: // value for 'excludeEmptyFolders'
 *   },
 * });
 */
export function useGetDocumentsTreeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentsTreeQuery, GetDocumentsTreeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetDocumentsTreeQuery, GetDocumentsTreeQueryVariables>(
    GetDocumentsTreeDocument,
    baseOptions
  );
}
export function useGetDocumentsTreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentsTreeQuery, GetDocumentsTreeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDocumentsTreeQuery, GetDocumentsTreeQueryVariables>(
    GetDocumentsTreeDocument,
    baseOptions
  );
}
export type GetDocumentsTreeQueryHookResult = ReturnType<typeof useGetDocumentsTreeQuery>;
export type GetDocumentsTreeLazyQueryHookResult = ReturnType<typeof useGetDocumentsTreeLazyQuery>;
export type GetDocumentsTreeQueryResult = ApolloReactCommon.QueryResult<
  GetDocumentsTreeQuery,
  GetDocumentsTreeQueryVariables
>;
export function refetchGetDocumentsTreeQuery(variables?: GetDocumentsTreeQueryVariables) {
  return { query: GetDocumentsTreeDocument, variables: variables };
}
export const GetFolderDocumentsHierarchyDocument = gql`
  query getFolderDocumentsHierarchy($creatorTemplate: ID, $excludeEmptyFolders: Boolean) {
    folderDocumentsHierarchy(
      input: { where: { creatorTemplate: $creatorTemplate }, options: { excludeEmptyFolders: $excludeEmptyFolders } }
    )
  }
`;

/**
 * __useGetFolderDocumentsHierarchyQuery__
 *
 * To run a query within a React component, call `useGetFolderDocumentsHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderDocumentsHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderDocumentsHierarchyQuery({
 *   variables: {
 *      creatorTemplate: // value for 'creatorTemplate'
 *      excludeEmptyFolders: // value for 'excludeEmptyFolders'
 *   },
 * });
 */
export function useGetFolderDocumentsHierarchyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFolderDocumentsHierarchyQuery,
    GetFolderDocumentsHierarchyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetFolderDocumentsHierarchyQuery, GetFolderDocumentsHierarchyQueryVariables>(
    GetFolderDocumentsHierarchyDocument,
    baseOptions
  );
}
export function useGetFolderDocumentsHierarchyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFolderDocumentsHierarchyQuery,
    GetFolderDocumentsHierarchyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetFolderDocumentsHierarchyQuery, GetFolderDocumentsHierarchyQueryVariables>(
    GetFolderDocumentsHierarchyDocument,
    baseOptions
  );
}
export type GetFolderDocumentsHierarchyQueryHookResult = ReturnType<typeof useGetFolderDocumentsHierarchyQuery>;
export type GetFolderDocumentsHierarchyLazyQueryHookResult = ReturnType<typeof useGetFolderDocumentsHierarchyLazyQuery>;
export type GetFolderDocumentsHierarchyQueryResult = ApolloReactCommon.QueryResult<
  GetFolderDocumentsHierarchyQuery,
  GetFolderDocumentsHierarchyQueryVariables
>;
export function refetchGetFolderDocumentsHierarchyQuery(variables?: GetFolderDocumentsHierarchyQueryVariables) {
  return { query: GetFolderDocumentsHierarchyDocument, variables: variables };
}
export const GetFolderTemplatesHierarchyDocument = gql`
  query getFolderTemplatesHierarchy {
    folderHierarchy(type: TEMPLATES)
  }
`;

/**
 * __useGetFolderTemplatesHierarchyQuery__
 *
 * To run a query within a React component, call `useGetFolderTemplatesHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderTemplatesHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderTemplatesHierarchyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFolderTemplatesHierarchyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFolderTemplatesHierarchyQuery,
    GetFolderTemplatesHierarchyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetFolderTemplatesHierarchyQuery, GetFolderTemplatesHierarchyQueryVariables>(
    GetFolderTemplatesHierarchyDocument,
    baseOptions
  );
}
export function useGetFolderTemplatesHierarchyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFolderTemplatesHierarchyQuery,
    GetFolderTemplatesHierarchyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetFolderTemplatesHierarchyQuery, GetFolderTemplatesHierarchyQueryVariables>(
    GetFolderTemplatesHierarchyDocument,
    baseOptions
  );
}
export type GetFolderTemplatesHierarchyQueryHookResult = ReturnType<typeof useGetFolderTemplatesHierarchyQuery>;
export type GetFolderTemplatesHierarchyLazyQueryHookResult = ReturnType<typeof useGetFolderTemplatesHierarchyLazyQuery>;
export type GetFolderTemplatesHierarchyQueryResult = ApolloReactCommon.QueryResult<
  GetFolderTemplatesHierarchyQuery,
  GetFolderTemplatesHierarchyQueryVariables
>;
export function refetchGetFolderTemplatesHierarchyQuery(variables?: GetFolderTemplatesHierarchyQueryVariables) {
  return { query: GetFolderTemplatesHierarchyDocument, variables: variables };
}
export const ListDocumentsWithDataDocument = gql`
  query ListDocumentsWithData($ids: [ID!]) {
    result: documents(input: { ids: $ids }) {
      ...DocumentData
    }
  }
  ${DocumentDataFragmentDoc}
`;

/**
 * __useListDocumentsWithDataQuery__
 *
 * To run a query within a React component, call `useListDocumentsWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsWithDataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListDocumentsWithDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListDocumentsWithDataQuery, ListDocumentsWithDataQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListDocumentsWithDataQuery, ListDocumentsWithDataQueryVariables>(
    ListDocumentsWithDataDocument,
    baseOptions
  );
}
export function useListDocumentsWithDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListDocumentsWithDataQuery, ListDocumentsWithDataQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListDocumentsWithDataQuery, ListDocumentsWithDataQueryVariables>(
    ListDocumentsWithDataDocument,
    baseOptions
  );
}
export type ListDocumentsWithDataQueryHookResult = ReturnType<typeof useListDocumentsWithDataQuery>;
export type ListDocumentsWithDataLazyQueryHookResult = ReturnType<typeof useListDocumentsWithDataLazyQuery>;
export type ListDocumentsWithDataQueryResult = ApolloReactCommon.QueryResult<
  ListDocumentsWithDataQuery,
  ListDocumentsWithDataQueryVariables
>;
export function refetchListDocumentsWithDataQuery(variables?: ListDocumentsWithDataQueryVariables) {
  return { query: ListDocumentsWithDataDocument, variables: variables };
}
export const ListTasksDocument = gql`
  query ListTasks($input: TaskInput) {
    pagedTasks(input: $input) {
      tasks {
        ...TaskData
      }
      totalItems
    }
  }
  ${TaskDataFragmentDoc}
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, baseOptions);
}
export function useListTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, baseOptions);
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<typeof useListTasksLazyQuery>;
export type ListTasksQueryResult = ApolloReactCommon.QueryResult<ListTasksQuery, ListTasksQueryVariables>;
export function refetchListTasksQuery(variables?: ListTasksQueryVariables) {
  return { query: ListTasksDocument, variables: variables };
}
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($id: ID!) {
    isDeleted: deleteDocument(id: $id)
  }
`;
export type DeleteDocumentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(
    DeleteDocumentDocument,
    baseOptions
  );
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = ApolloReactCommon.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const CopyDocumentDocument = gql`
  mutation CopyDocument($id: ID!, $folder: ID!, $input: CopyDocumentInput) {
    copiedDocument: copyDocument(id: $id, folder: $folder, input: $input) {
      id
      folder {
        id
        title
      }
    }
  }
`;
export type CopyDocumentMutationFn = ApolloReactCommon.MutationFunction<
  CopyDocumentMutation,
  CopyDocumentMutationVariables
>;

/**
 * __useCopyDocumentMutation__
 *
 * To run a mutation, you first call `useCopyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDocumentMutation, { data, loading, error }] = useCopyDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folder: // value for 'folder'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CopyDocumentMutation, CopyDocumentMutationVariables>
) {
  return ApolloReactHooks.useMutation<CopyDocumentMutation, CopyDocumentMutationVariables>(
    CopyDocumentDocument,
    baseOptions
  );
}
export type CopyDocumentMutationHookResult = ReturnType<typeof useCopyDocumentMutation>;
export type CopyDocumentMutationResult = ApolloReactCommon.MutationResult<CopyDocumentMutation>;
export type CopyDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyDocumentMutation,
  CopyDocumentMutationVariables
>;
export const TemplateToDocumentDocument = gql`
  mutation TemplateToDocument($id: ID!, $folder: ID!, $title: String) {
    templateToDocument(id: $id, folder: $folder, input: { title: $title }) {
      id
      slug
      createdAt
      createdBy
    }
  }
`;
export type TemplateToDocumentMutationFn = ApolloReactCommon.MutationFunction<
  TemplateToDocumentMutation,
  TemplateToDocumentMutationVariables
>;

/**
 * __useTemplateToDocumentMutation__
 *
 * To run a mutation, you first call `useTemplateToDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateToDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateToDocumentMutation, { data, loading, error }] = useTemplateToDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folder: // value for 'folder'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useTemplateToDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TemplateToDocumentMutation, TemplateToDocumentMutationVariables>
) {
  return ApolloReactHooks.useMutation<TemplateToDocumentMutation, TemplateToDocumentMutationVariables>(
    TemplateToDocumentDocument,
    baseOptions
  );
}
export type TemplateToDocumentMutationHookResult = ReturnType<typeof useTemplateToDocumentMutation>;
export type TemplateToDocumentMutationResult = ApolloReactCommon.MutationResult<TemplateToDocumentMutation>;
export type TemplateToDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TemplateToDocumentMutation,
  TemplateToDocumentMutationVariables
>;
export const ListRelatedTasksDocument = gql`
  query ListRelatedTasks($document: ID!) {
    document(id: $document) {
      id
      relatedTasks {
        tasks {
          ...TaskData
        }
        totalItems
      }
    }
  }
  ${TaskDataFragmentDoc}
`;

/**
 * __useListRelatedTasksQuery__
 *
 * To run a query within a React component, call `useListRelatedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRelatedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRelatedTasksQuery({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useListRelatedTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListRelatedTasksQuery, ListRelatedTasksQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListRelatedTasksQuery, ListRelatedTasksQueryVariables>(
    ListRelatedTasksDocument,
    baseOptions
  );
}
export function useListRelatedTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListRelatedTasksQuery, ListRelatedTasksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListRelatedTasksQuery, ListRelatedTasksQueryVariables>(
    ListRelatedTasksDocument,
    baseOptions
  );
}
export type ListRelatedTasksQueryHookResult = ReturnType<typeof useListRelatedTasksQuery>;
export type ListRelatedTasksLazyQueryHookResult = ReturnType<typeof useListRelatedTasksLazyQuery>;
export type ListRelatedTasksQueryResult = ApolloReactCommon.QueryResult<
  ListRelatedTasksQuery,
  ListRelatedTasksQueryVariables
>;
export function refetchListRelatedTasksQuery(variables?: ListRelatedTasksQueryVariables) {
  return { query: ListRelatedTasksDocument, variables: variables };
}
export const CreateArchiveDocumentAttachmentsDocument = gql`
  mutation CreateArchiveDocumentAttachments($input: ArchiveDocumentAttachmentsInput!) {
    createArchiveDocumentAttachments(input: $input) {
      ...ArchiveDocumentAttachmentsData
    }
  }
  ${ArchiveDocumentAttachmentsDataFragmentDoc}
`;
export type CreateArchiveDocumentAttachmentsMutationFn = ApolloReactCommon.MutationFunction<
  CreateArchiveDocumentAttachmentsMutation,
  CreateArchiveDocumentAttachmentsMutationVariables
>;

/**
 * __useCreateArchiveDocumentAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateArchiveDocumentAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArchiveDocumentAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArchiveDocumentAttachmentsMutation, { data, loading, error }] = useCreateArchiveDocumentAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArchiveDocumentAttachmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateArchiveDocumentAttachmentsMutation,
    CreateArchiveDocumentAttachmentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateArchiveDocumentAttachmentsMutation,
    CreateArchiveDocumentAttachmentsMutationVariables
  >(CreateArchiveDocumentAttachmentsDocument, baseOptions);
}
export type CreateArchiveDocumentAttachmentsMutationHookResult = ReturnType<
  typeof useCreateArchiveDocumentAttachmentsMutation
>;
export type CreateArchiveDocumentAttachmentsMutationResult = ApolloReactCommon.MutationResult<
  CreateArchiveDocumentAttachmentsMutation
>;
export type CreateArchiveDocumentAttachmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateArchiveDocumentAttachmentsMutation,
  CreateArchiveDocumentAttachmentsMutationVariables
>;
export const GetArchiveDocumentAttachmentsDocument = gql`
  query GetArchiveDocumentAttachments($id: ID!) {
    archiveDocumentAttachments(id: $id) {
      ...ArchiveDocumentAttachmentsData
    }
  }
  ${ArchiveDocumentAttachmentsDataFragmentDoc}
`;

/**
 * __useGetArchiveDocumentAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetArchiveDocumentAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchiveDocumentAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchiveDocumentAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArchiveDocumentAttachmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetArchiveDocumentAttachmentsQuery,
    GetArchiveDocumentAttachmentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetArchiveDocumentAttachmentsQuery, GetArchiveDocumentAttachmentsQueryVariables>(
    GetArchiveDocumentAttachmentsDocument,
    baseOptions
  );
}
export function useGetArchiveDocumentAttachmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetArchiveDocumentAttachmentsQuery,
    GetArchiveDocumentAttachmentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetArchiveDocumentAttachmentsQuery, GetArchiveDocumentAttachmentsQueryVariables>(
    GetArchiveDocumentAttachmentsDocument,
    baseOptions
  );
}
export type GetArchiveDocumentAttachmentsQueryHookResult = ReturnType<typeof useGetArchiveDocumentAttachmentsQuery>;
export type GetArchiveDocumentAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetArchiveDocumentAttachmentsLazyQuery
>;
export type GetArchiveDocumentAttachmentsQueryResult = ApolloReactCommon.QueryResult<
  GetArchiveDocumentAttachmentsQuery,
  GetArchiveDocumentAttachmentsQueryVariables
>;
export function refetchGetArchiveDocumentAttachmentsQuery(variables?: GetArchiveDocumentAttachmentsQueryVariables) {
  return { query: GetArchiveDocumentAttachmentsDocument, variables: variables };
}
export const CreateDocumentFromTemplateDocument = gql`
  mutation CreateDocumentFromTemplate($id: ID!, $folder: ID!, $input: CreateDocumentInput!) {
    createDocumentFromTemplate(id: $id, folder: $folder, input: $input) {
      id
      title
      slug
      createdAt
      createdBy
    }
  }
`;
export type CreateDocumentFromTemplateMutationFn = ApolloReactCommon.MutationFunction<
  CreateDocumentFromTemplateMutation,
  CreateDocumentFromTemplateMutationVariables
>;

/**
 * __useCreateDocumentFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDocumentFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentFromTemplateMutation, { data, loading, error }] = useCreateDocumentFromTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folder: // value for 'folder'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentFromTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDocumentFromTemplateMutation,
    CreateDocumentFromTemplateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateDocumentFromTemplateMutation, CreateDocumentFromTemplateMutationVariables>(
    CreateDocumentFromTemplateDocument,
    baseOptions
  );
}
export type CreateDocumentFromTemplateMutationHookResult = ReturnType<typeof useCreateDocumentFromTemplateMutation>;
export type CreateDocumentFromTemplateMutationResult = ApolloReactCommon.MutationResult<
  CreateDocumentFromTemplateMutation
>;
export type CreateDocumentFromTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDocumentFromTemplateMutation,
  CreateDocumentFromTemplateMutationVariables
>;
export const ListTemplatesForSelectionDocument = gql`
  query ListTemplatesForSelection {
    templates {
      value: id
      label: title
    }
  }
`;

/**
 * __useListTemplatesForSelectionQuery__
 *
 * To run a query within a React component, call `useListTemplatesForSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplatesForSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplatesForSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTemplatesForSelectionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListTemplatesForSelectionQuery,
    ListTemplatesForSelectionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListTemplatesForSelectionQuery, ListTemplatesForSelectionQueryVariables>(
    ListTemplatesForSelectionDocument,
    baseOptions
  );
}
export function useListTemplatesForSelectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListTemplatesForSelectionQuery,
    ListTemplatesForSelectionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListTemplatesForSelectionQuery, ListTemplatesForSelectionQueryVariables>(
    ListTemplatesForSelectionDocument,
    baseOptions
  );
}
export type ListTemplatesForSelectionQueryHookResult = ReturnType<typeof useListTemplatesForSelectionQuery>;
export type ListTemplatesForSelectionLazyQueryHookResult = ReturnType<typeof useListTemplatesForSelectionLazyQuery>;
export type ListTemplatesForSelectionQueryResult = ApolloReactCommon.QueryResult<
  ListTemplatesForSelectionQuery,
  ListTemplatesForSelectionQueryVariables
>;
export function refetchListTemplatesForSelectionQuery(variables?: ListTemplatesForSelectionQueryVariables) {
  return { query: ListTemplatesForSelectionDocument, variables: variables };
}
export const GetBackendGitCommitDocument = gql`
  query GetBackendGitCommit {
    backendGitCommit {
      gitCommit
    }
  }
`;

/**
 * __useGetBackendGitCommitQuery__
 *
 * To run a query within a React component, call `useGetBackendGitCommitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackendGitCommitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackendGitCommitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBackendGitCommitQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetBackendGitCommitQuery, GetBackendGitCommitQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetBackendGitCommitQuery, GetBackendGitCommitQueryVariables>(
    GetBackendGitCommitDocument,
    baseOptions
  );
}
export function useGetBackendGitCommitLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackendGitCommitQuery, GetBackendGitCommitQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetBackendGitCommitQuery, GetBackendGitCommitQueryVariables>(
    GetBackendGitCommitDocument,
    baseOptions
  );
}
export type GetBackendGitCommitQueryHookResult = ReturnType<typeof useGetBackendGitCommitQuery>;
export type GetBackendGitCommitLazyQueryHookResult = ReturnType<typeof useGetBackendGitCommitLazyQuery>;
export type GetBackendGitCommitQueryResult = ApolloReactCommon.QueryResult<
  GetBackendGitCommitQuery,
  GetBackendGitCommitQueryVariables
>;
export function refetchGetBackendGitCommitQuery(variables?: GetBackendGitCommitQueryVariables) {
  return { query: GetBackendGitCommitDocument, variables: variables };
}
export const GetGraphQlGitCommitDocument = gql`
  query GetGraphQLGitCommit {
    graphQLGitCommit {
      gitCommit
    }
  }
`;

/**
 * __useGetGraphQlGitCommitQuery__
 *
 * To run a query within a React component, call `useGetGraphQlGitCommitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphQlGitCommitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphQlGitCommitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGraphQlGitCommitQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGraphQlGitCommitQuery, GetGraphQlGitCommitQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetGraphQlGitCommitQuery, GetGraphQlGitCommitQueryVariables>(
    GetGraphQlGitCommitDocument,
    baseOptions
  );
}
export function useGetGraphQlGitCommitLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGraphQlGitCommitQuery, GetGraphQlGitCommitQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetGraphQlGitCommitQuery, GetGraphQlGitCommitQueryVariables>(
    GetGraphQlGitCommitDocument,
    baseOptions
  );
}
export type GetGraphQlGitCommitQueryHookResult = ReturnType<typeof useGetGraphQlGitCommitQuery>;
export type GetGraphQlGitCommitLazyQueryHookResult = ReturnType<typeof useGetGraphQlGitCommitLazyQuery>;
export type GetGraphQlGitCommitQueryResult = ApolloReactCommon.QueryResult<
  GetGraphQlGitCommitQuery,
  GetGraphQlGitCommitQueryVariables
>;
export function refetchGetGraphQlGitCommitQuery(variables?: GetGraphQlGitCommitQueryVariables) {
  return { query: GetGraphQlGitCommitDocument, variables: variables };
}
export const DeleteTemplateDocument = gql`
  mutation DeleteTemplate($id: ID!) {
    isDeleted: deleteTemplate(id: $id)
  }
`;
export type DeleteTemplateMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(
    DeleteTemplateDocument,
    baseOptions
  );
}
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;
export const CopyTemplateDocument = gql`
  mutation CopyTemplate($id: ID!, $folder: ID!, $input: CopyTemplateInput) {
    copiedTemplate: copyTemplate(id: $id, folder: $folder, input: $input) {
      id
      folder {
        id
        title
      }
    }
  }
`;
export type CopyTemplateMutationFn = ApolloReactCommon.MutationFunction<
  CopyTemplateMutation,
  CopyTemplateMutationVariables
>;

/**
 * __useCopyTemplateMutation__
 *
 * To run a mutation, you first call `useCopyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTemplateMutation, { data, loading, error }] = useCopyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folder: // value for 'folder'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CopyTemplateMutation, CopyTemplateMutationVariables>
) {
  return ApolloReactHooks.useMutation<CopyTemplateMutation, CopyTemplateMutationVariables>(
    CopyTemplateDocument,
    baseOptions
  );
}
export type CopyTemplateMutationHookResult = ReturnType<typeof useCopyTemplateMutation>;
export type CopyTemplateMutationResult = ApolloReactCommon.MutationResult<CopyTemplateMutation>;
export type CopyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyTemplateMutation,
  CopyTemplateMutationVariables
>;
export const InviteNewUserDocument = gql`
  mutation InviteNewUser(
    $email: String!
    $firstname: String!
    $lastname: String!
    $subscription: String!
    $accessGroups: [String!]!
  ) {
    inviteDifUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      subscription: $subscription
      accessGroups: $accessGroups
    ) {
      email
    }
  }
`;
export type InviteNewUserMutationFn = ApolloReactCommon.MutationFunction<
  InviteNewUserMutation,
  InviteNewUserMutationVariables
>;

/**
 * __useInviteNewUserMutation__
 *
 * To run a mutation, you first call `useInviteNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteNewUserMutation, { data, loading, error }] = useInviteNewUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      subscription: // value for 'subscription'
 *      accessGroups: // value for 'accessGroups'
 *   },
 * });
 */
export function useInviteNewUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InviteNewUserMutation, InviteNewUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<InviteNewUserMutation, InviteNewUserMutationVariables>(
    InviteNewUserDocument,
    baseOptions
  );
}
export type InviteNewUserMutationHookResult = ReturnType<typeof useInviteNewUserMutation>;
export type InviteNewUserMutationResult = ApolloReactCommon.MutationResult<InviteNewUserMutation>;
export type InviteNewUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteNewUserMutation,
  InviteNewUserMutationVariables
>;
export const CreateModuleDocumentsDocument = gql`
  mutation createModuleDocuments($moduleDocumentsInput: ModuleDocumentsInput!) {
    createModuleDocuments(moduleDocumentsInput: $moduleDocumentsInput)
  }
`;
export type CreateModuleDocumentsMutationFn = ApolloReactCommon.MutationFunction<
  CreateModuleDocumentsMutation,
  CreateModuleDocumentsMutationVariables
>;

/**
 * __useCreateModuleDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateModuleDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleDocumentsMutation, { data, loading, error }] = useCreateModuleDocumentsMutation({
 *   variables: {
 *      moduleDocumentsInput: // value for 'moduleDocumentsInput'
 *   },
 * });
 */
export function useCreateModuleDocumentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateModuleDocumentsMutation,
    CreateModuleDocumentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateModuleDocumentsMutation, CreateModuleDocumentsMutationVariables>(
    CreateModuleDocumentsDocument,
    baseOptions
  );
}
export type CreateModuleDocumentsMutationHookResult = ReturnType<typeof useCreateModuleDocumentsMutation>;
export type CreateModuleDocumentsMutationResult = ApolloReactCommon.MutationResult<CreateModuleDocumentsMutation>;
export type CreateModuleDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateModuleDocumentsMutation,
  CreateModuleDocumentsMutationVariables
>;
export const CreateModuleTemplatesDocument = gql`
  mutation createModuleTemplates($moduleTemplatesInput: ModuleTemplatesInput!) {
    createModuleTemplates(moduleTemplatesInput: $moduleTemplatesInput)
  }
`;
export type CreateModuleTemplatesMutationFn = ApolloReactCommon.MutationFunction<
  CreateModuleTemplatesMutation,
  CreateModuleTemplatesMutationVariables
>;

/**
 * __useCreateModuleTemplatesMutation__
 *
 * To run a mutation, you first call `useCreateModuleTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleTemplatesMutation, { data, loading, error }] = useCreateModuleTemplatesMutation({
 *   variables: {
 *      moduleTemplatesInput: // value for 'moduleTemplatesInput'
 *   },
 * });
 */
export function useCreateModuleTemplatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateModuleTemplatesMutation,
    CreateModuleTemplatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateModuleTemplatesMutation, CreateModuleTemplatesMutationVariables>(
    CreateModuleTemplatesDocument,
    baseOptions
  );
}
export type CreateModuleTemplatesMutationHookResult = ReturnType<typeof useCreateModuleTemplatesMutation>;
export type CreateModuleTemplatesMutationResult = ApolloReactCommon.MutationResult<CreateModuleTemplatesMutation>;
export type CreateModuleTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateModuleTemplatesMutation,
  CreateModuleTemplatesMutationVariables
>;
export const DeleteModuleDocumentsDocument = gql`
  mutation deleteModuleDocuments($moduleDocumentsInput: ModuleDocumentsInput!) {
    deleteModuleDocuments(moduleDocumentsInput: $moduleDocumentsInput)
  }
`;
export type DeleteModuleDocumentsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteModuleDocumentsMutation,
  DeleteModuleDocumentsMutationVariables
>;

/**
 * __useDeleteModuleDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteModuleDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleDocumentsMutation, { data, loading, error }] = useDeleteModuleDocumentsMutation({
 *   variables: {
 *      moduleDocumentsInput: // value for 'moduleDocumentsInput'
 *   },
 * });
 */
export function useDeleteModuleDocumentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteModuleDocumentsMutation,
    DeleteModuleDocumentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteModuleDocumentsMutation, DeleteModuleDocumentsMutationVariables>(
    DeleteModuleDocumentsDocument,
    baseOptions
  );
}
export type DeleteModuleDocumentsMutationHookResult = ReturnType<typeof useDeleteModuleDocumentsMutation>;
export type DeleteModuleDocumentsMutationResult = ApolloReactCommon.MutationResult<DeleteModuleDocumentsMutation>;
export type DeleteModuleDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteModuleDocumentsMutation,
  DeleteModuleDocumentsMutationVariables
>;
export const DeleteModuleTemplatesDocument = gql`
  mutation deleteModuleTemplates($moduleTemplatesInput: ModuleTemplatesInput!) {
    deleteModuleTemplates(moduleTemplatesInput: $moduleTemplatesInput)
  }
`;
export type DeleteModuleTemplatesMutationFn = ApolloReactCommon.MutationFunction<
  DeleteModuleTemplatesMutation,
  DeleteModuleTemplatesMutationVariables
>;

/**
 * __useDeleteModuleTemplatesMutation__
 *
 * To run a mutation, you first call `useDeleteModuleTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleTemplatesMutation, { data, loading, error }] = useDeleteModuleTemplatesMutation({
 *   variables: {
 *      moduleTemplatesInput: // value for 'moduleTemplatesInput'
 *   },
 * });
 */
export function useDeleteModuleTemplatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteModuleTemplatesMutation,
    DeleteModuleTemplatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteModuleTemplatesMutation, DeleteModuleTemplatesMutationVariables>(
    DeleteModuleTemplatesDocument,
    baseOptions
  );
}
export type DeleteModuleTemplatesMutationHookResult = ReturnType<typeof useDeleteModuleTemplatesMutation>;
export type DeleteModuleTemplatesMutationResult = ApolloReactCommon.MutationResult<DeleteModuleTemplatesMutation>;
export type DeleteModuleTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteModuleTemplatesMutation,
  DeleteModuleTemplatesMutationVariables
>;
export const AssociatedModulesForDocumentDocument = gql`
  query associatedModulesForDocument($id: ID!) {
    associatedModulesForDocument(id: $id) {
      id
      name
      description
    }
  }
`;

/**
 * __useAssociatedModulesForDocumentQuery__
 *
 * To run a query within a React component, call `useAssociatedModulesForDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociatedModulesForDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociatedModulesForDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssociatedModulesForDocumentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssociatedModulesForDocumentQuery,
    AssociatedModulesForDocumentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AssociatedModulesForDocumentQuery, AssociatedModulesForDocumentQueryVariables>(
    AssociatedModulesForDocumentDocument,
    baseOptions
  );
}
export function useAssociatedModulesForDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssociatedModulesForDocumentQuery,
    AssociatedModulesForDocumentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AssociatedModulesForDocumentQuery, AssociatedModulesForDocumentQueryVariables>(
    AssociatedModulesForDocumentDocument,
    baseOptions
  );
}
export type AssociatedModulesForDocumentQueryHookResult = ReturnType<typeof useAssociatedModulesForDocumentQuery>;
export type AssociatedModulesForDocumentLazyQueryHookResult = ReturnType<
  typeof useAssociatedModulesForDocumentLazyQuery
>;
export type AssociatedModulesForDocumentQueryResult = ApolloReactCommon.QueryResult<
  AssociatedModulesForDocumentQuery,
  AssociatedModulesForDocumentQueryVariables
>;
export function refetchAssociatedModulesForDocumentQuery(variables?: AssociatedModulesForDocumentQueryVariables) {
  return { query: AssociatedModulesForDocumentDocument, variables: variables };
}
export const ModuleAssociationsDocument = gql`
  query moduleAssociations($id: ID!) {
    moduleAssociations(id: $id) {
      templates {
        id
        title
        creatorTemplateId
        creatorTemplateTitle
        customerName
        customerId
        path
      }
      documents {
        id
        title
        creatorTemplateId
        creatorTemplateTitle
        customerName
        customerId
        path
      }
    }
  }
`;

/**
 * __useModuleAssociationsQuery__
 *
 * To run a query within a React component, call `useModuleAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleAssociationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModuleAssociationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ModuleAssociationsQuery, ModuleAssociationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ModuleAssociationsQuery, ModuleAssociationsQueryVariables>(
    ModuleAssociationsDocument,
    baseOptions
  );
}
export function useModuleAssociationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModuleAssociationsQuery, ModuleAssociationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ModuleAssociationsQuery, ModuleAssociationsQueryVariables>(
    ModuleAssociationsDocument,
    baseOptions
  );
}
export type ModuleAssociationsQueryHookResult = ReturnType<typeof useModuleAssociationsQuery>;
export type ModuleAssociationsLazyQueryHookResult = ReturnType<typeof useModuleAssociationsLazyQuery>;
export type ModuleAssociationsQueryResult = ApolloReactCommon.QueryResult<
  ModuleAssociationsQuery,
  ModuleAssociationsQueryVariables
>;
export function refetchModuleAssociationsQuery(variables?: ModuleAssociationsQueryVariables) {
  return { query: ModuleAssociationsDocument, variables: variables };
}
export const GetModuleTreeDocument = gql`
  query getModuleTree {
    moduleTree
  }
`;

/**
 * __useGetModuleTreeQuery__
 *
 * To run a query within a React component, call `useGetModuleTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModuleTreeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetModuleTreeQuery, GetModuleTreeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetModuleTreeQuery, GetModuleTreeQueryVariables>(GetModuleTreeDocument, baseOptions);
}
export function useGetModuleTreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModuleTreeQuery, GetModuleTreeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetModuleTreeQuery, GetModuleTreeQueryVariables>(
    GetModuleTreeDocument,
    baseOptions
  );
}
export type GetModuleTreeQueryHookResult = ReturnType<typeof useGetModuleTreeQuery>;
export type GetModuleTreeLazyQueryHookResult = ReturnType<typeof useGetModuleTreeLazyQuery>;
export type GetModuleTreeQueryResult = ApolloReactCommon.QueryResult<GetModuleTreeQuery, GetModuleTreeQueryVariables>;
export function refetchGetModuleTreeQuery(variables?: GetModuleTreeQueryVariables) {
  return { query: GetModuleTreeDocument, variables: variables };
}
export const AssociatedModulesForTemplateDocument = gql`
  query associatedModulesForTemplate($id: ID!) {
    associatedModulesForTemplate(id: $id) {
      id
      name
      description
    }
  }
`;

/**
 * __useAssociatedModulesForTemplateQuery__
 *
 * To run a query within a React component, call `useAssociatedModulesForTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociatedModulesForTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociatedModulesForTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssociatedModulesForTemplateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AssociatedModulesForTemplateQuery,
    AssociatedModulesForTemplateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AssociatedModulesForTemplateQuery, AssociatedModulesForTemplateQueryVariables>(
    AssociatedModulesForTemplateDocument,
    baseOptions
  );
}
export function useAssociatedModulesForTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssociatedModulesForTemplateQuery,
    AssociatedModulesForTemplateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AssociatedModulesForTemplateQuery, AssociatedModulesForTemplateQueryVariables>(
    AssociatedModulesForTemplateDocument,
    baseOptions
  );
}
export type AssociatedModulesForTemplateQueryHookResult = ReturnType<typeof useAssociatedModulesForTemplateQuery>;
export type AssociatedModulesForTemplateLazyQueryHookResult = ReturnType<
  typeof useAssociatedModulesForTemplateLazyQuery
>;
export type AssociatedModulesForTemplateQueryResult = ApolloReactCommon.QueryResult<
  AssociatedModulesForTemplateQuery,
  AssociatedModulesForTemplateQueryVariables
>;
export function refetchAssociatedModulesForTemplateQuery(variables?: AssociatedModulesForTemplateQueryVariables) {
  return { query: AssociatedModulesForTemplateDocument, variables: variables };
}
export const CreateAccessGroupDocument = gql`
  mutation CreateAccessGroup($input: CreateAccessGroupInput!) {
    createAccessGroup(input: $input) {
      id
    }
  }
`;
export type CreateAccessGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateAccessGroupMutation,
  CreateAccessGroupMutationVariables
>;

/**
 * __useCreateAccessGroupMutation__
 *
 * To run a mutation, you first call `useCreateAccessGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessGroupMutation, { data, loading, error }] = useCreateAccessGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccessGroupMutation, CreateAccessGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateAccessGroupMutation, CreateAccessGroupMutationVariables>(
    CreateAccessGroupDocument,
    baseOptions
  );
}
export type CreateAccessGroupMutationHookResult = ReturnType<typeof useCreateAccessGroupMutation>;
export type CreateAccessGroupMutationResult = ApolloReactCommon.MutationResult<CreateAccessGroupMutation>;
export type CreateAccessGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAccessGroupMutation,
  CreateAccessGroupMutationVariables
>;
export const DeleteAccessGroupDocument = gql`
  mutation DeleteAccessGroup($id: ID!) {
    deleteAccessGroup(id: $id)
  }
`;
export type DeleteAccessGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAccessGroupMutation,
  DeleteAccessGroupMutationVariables
>;

/**
 * __useDeleteAccessGroupMutation__
 *
 * To run a mutation, you first call `useDeleteAccessGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessGroupMutation, { data, loading, error }] = useDeleteAccessGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccessGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccessGroupMutation, DeleteAccessGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteAccessGroupMutation, DeleteAccessGroupMutationVariables>(
    DeleteAccessGroupDocument,
    baseOptions
  );
}
export type DeleteAccessGroupMutationHookResult = ReturnType<typeof useDeleteAccessGroupMutation>;
export type DeleteAccessGroupMutationResult = ApolloReactCommon.MutationResult<DeleteAccessGroupMutation>;
export type DeleteAccessGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAccessGroupMutation,
  DeleteAccessGroupMutationVariables
>;
export const ListAccessGroupsDocument = gql`
  query ListAccessGroups($id: [ID!]) {
    accessGroups(input: { id: $id }) {
      id
      title
      users {
        id
        firstname
        lastname
        email
      }
      accessRights {
        id
        type
        subject
        access
      }
    }
  }
`;

/**
 * __useListAccessGroupsQuery__
 *
 * To run a query within a React component, call `useListAccessGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccessGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccessGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListAccessGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListAccessGroupsQuery, ListAccessGroupsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListAccessGroupsQuery, ListAccessGroupsQueryVariables>(
    ListAccessGroupsDocument,
    baseOptions
  );
}
export function useListAccessGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAccessGroupsQuery, ListAccessGroupsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListAccessGroupsQuery, ListAccessGroupsQueryVariables>(
    ListAccessGroupsDocument,
    baseOptions
  );
}
export type ListAccessGroupsQueryHookResult = ReturnType<typeof useListAccessGroupsQuery>;
export type ListAccessGroupsLazyQueryHookResult = ReturnType<typeof useListAccessGroupsLazyQuery>;
export type ListAccessGroupsQueryResult = ApolloReactCommon.QueryResult<
  ListAccessGroupsQuery,
  ListAccessGroupsQueryVariables
>;
export function refetchListAccessGroupsQuery(variables?: ListAccessGroupsQueryVariables) {
  return { query: ListAccessGroupsDocument, variables: variables };
}
export const UpdateAccessGroupDocument = gql`
  mutation UpdateAccessGroup($id: ID!, $input: UpdateAccessGroupInput!) {
    updateAccessGroup(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateAccessGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAccessGroupMutation,
  UpdateAccessGroupMutationVariables
>;

/**
 * __useUpdateAccessGroupMutation__
 *
 * To run a mutation, you first call `useUpdateAccessGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessGroupMutation, { data, loading, error }] = useUpdateAccessGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessGroupMutation, UpdateAccessGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateAccessGroupMutation, UpdateAccessGroupMutationVariables>(
    UpdateAccessGroupDocument,
    baseOptions
  );
}
export type UpdateAccessGroupMutationHookResult = ReturnType<typeof useUpdateAccessGroupMutation>;
export type UpdateAccessGroupMutationResult = ApolloReactCommon.MutationResult<UpdateAccessGroupMutation>;
export type UpdateAccessGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAccessGroupMutation,
  UpdateAccessGroupMutationVariables
>;
export const CopyFolderDocument = gql`
  mutation CopyFolder($id: ID!, $type: FolderType!, $input: FolderCopyInput!) {
    createdFolder: copyFolder(id: $id, type: $type, input: $input) {
      id
      title
    }
  }
`;
export type CopyFolderMutationFn = ApolloReactCommon.MutationFunction<CopyFolderMutation, CopyFolderMutationVariables>;

/**
 * __useCopyFolderMutation__
 *
 * To run a mutation, you first call `useCopyFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyFolderMutation, { data, loading, error }] = useCopyFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyFolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CopyFolderMutation, CopyFolderMutationVariables>
) {
  return ApolloReactHooks.useMutation<CopyFolderMutation, CopyFolderMutationVariables>(CopyFolderDocument, baseOptions);
}
export type CopyFolderMutationHookResult = ReturnType<typeof useCopyFolderMutation>;
export type CopyFolderMutationResult = ApolloReactCommon.MutationResult<CopyFolderMutation>;
export type CopyFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyFolderMutation,
  CopyFolderMutationVariables
>;
export const CreateFolderDocument = gql`
  mutation CreateFolder($type: FolderType!, $input: FolderCreateInput!) {
    createdFolder: createFolder(type: $type, input: $input) {
      id
      title
    }
  }
`;
export type CreateFolderMutationFn = ApolloReactCommon.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(
    CreateFolderDocument,
    baseOptions
  );
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = ApolloReactCommon.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export const DeleteFolderDocument = gql`
  mutation DeleteFolder($id: ID!, $type: FolderType!) {
    deletedFolder: deleteFolder(id: $id, type: $type)
  }
`;
export type DeleteFolderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteFolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(
    DeleteFolderDocument,
    baseOptions
  );
}
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = ApolloReactCommon.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>;
export const UpdateFolderDocument = gql`
  mutation UpdateFolder($id: ID!, $type: FolderType!, $input: FolderUpdateInput!) {
    updatedFolder: updateFolder(id: $id, type: $type, input: $input) {
      id
      title
    }
  }
`;
export type UpdateFolderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(
    UpdateFolderDocument,
    baseOptions
  );
}
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = ApolloReactCommon.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>;
export const GetFolderTreeDocument = gql`
  query GetFolderTree($type: FolderType = DOCUMENTS) {
    folderTree: folderHierarchy(type: $type)
  }
`;

/**
 * __useGetFolderTreeQuery__
 *
 * To run a query within a React component, call `useGetFolderTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderTreeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetFolderTreeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetFolderTreeQuery, GetFolderTreeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetFolderTreeQuery, GetFolderTreeQueryVariables>(GetFolderTreeDocument, baseOptions);
}
export function useGetFolderTreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFolderTreeQuery, GetFolderTreeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetFolderTreeQuery, GetFolderTreeQueryVariables>(
    GetFolderTreeDocument,
    baseOptions
  );
}
export type GetFolderTreeQueryHookResult = ReturnType<typeof useGetFolderTreeQuery>;
export type GetFolderTreeLazyQueryHookResult = ReturnType<typeof useGetFolderTreeLazyQuery>;
export type GetFolderTreeQueryResult = ApolloReactCommon.QueryResult<GetFolderTreeQuery, GetFolderTreeQueryVariables>;
export function refetchGetFolderTreeQuery(variables?: GetFolderTreeQueryVariables) {
  return { query: GetFolderTreeDocument, variables: variables };
}
export const GetTemplateDocument = gql`
  query GetTemplate($id: ID!) {
    result: template(id: $id) {
      accessLevel
      ...TemplateData
    }
  }
  ${TemplateDataFragmentDoc}
`;

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
}
export function useGetTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
}
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<typeof useGetTemplateLazyQuery>;
export type GetTemplateQueryResult = ApolloReactCommon.QueryResult<GetTemplateQuery, GetTemplateQueryVariables>;
export function refetchGetTemplateQuery(variables?: GetTemplateQueryVariables) {
  return { query: GetTemplateDocument, variables: variables };
}
export const CreateTemplateDocument = gql`
  mutation CreateTemplate(
    $title: String!
    $folder: String!
    $referenceNumber: String!
    $templateCategory: DocumentCategoryType!
  ) {
    createdTemplate: createTemplate(
      input: { title: $title, folder: $folder, referenceNumber: $referenceNumber, templateCategory: $templateCategory }
    ) {
      id
      createdAt
      createdBy
    }
  }
`;
export type CreateTemplateMutationFn = ApolloReactCommon.MutationFunction<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      folder: // value for 'folder'
 *      referenceNumber: // value for 'referenceNumber'
 *      templateCategory: // value for 'templateCategory'
 *   },
 * });
 */
export function useCreateTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
    CreateTemplateDocument,
    baseOptions
  );
}
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;
export const GetTemplatesTreeDocument = gql`
  query GetTemplatesTree {
    templatesTree: getTemplatesTree {
      ... on TemplatesTreePayloadResult {
        result
      }
    }
  }
`;

/**
 * __useGetTemplatesTreeQuery__
 *
 * To run a query within a React component, call `useGetTemplatesTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesTreeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesTreeQuery, GetTemplatesTreeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetTemplatesTreeQuery, GetTemplatesTreeQueryVariables>(
    GetTemplatesTreeDocument,
    baseOptions
  );
}
export function useGetTemplatesTreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesTreeQuery, GetTemplatesTreeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetTemplatesTreeQuery, GetTemplatesTreeQueryVariables>(
    GetTemplatesTreeDocument,
    baseOptions
  );
}
export type GetTemplatesTreeQueryHookResult = ReturnType<typeof useGetTemplatesTreeQuery>;
export type GetTemplatesTreeLazyQueryHookResult = ReturnType<typeof useGetTemplatesTreeLazyQuery>;
export type GetTemplatesTreeQueryResult = ApolloReactCommon.QueryResult<
  GetTemplatesTreeQuery,
  GetTemplatesTreeQueryVariables
>;
export function refetchGetTemplatesTreeQuery(variables?: GetTemplatesTreeQueryVariables) {
  return { query: GetTemplatesTreeDocument, variables: variables };
}
export const GetTemplateDraftDocument = gql`
  query GetTemplateDraft($id: ID!) {
    result: templateDraft(id: $id) {
      ...TemplateData
    }
  }
  ${TemplateDataFragmentDoc}
`;

/**
 * __useGetTemplateDraftQuery__
 *
 * To run a query within a React component, call `useGetTemplateDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateDraftQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateDraftQuery, GetTemplateDraftQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetTemplateDraftQuery, GetTemplateDraftQueryVariables>(
    GetTemplateDraftDocument,
    baseOptions
  );
}
export function useGetTemplateDraftLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateDraftQuery, GetTemplateDraftQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetTemplateDraftQuery, GetTemplateDraftQueryVariables>(
    GetTemplateDraftDocument,
    baseOptions
  );
}
export type GetTemplateDraftQueryHookResult = ReturnType<typeof useGetTemplateDraftQuery>;
export type GetTemplateDraftLazyQueryHookResult = ReturnType<typeof useGetTemplateDraftLazyQuery>;
export type GetTemplateDraftQueryResult = ApolloReactCommon.QueryResult<
  GetTemplateDraftQuery,
  GetTemplateDraftQueryVariables
>;
export function refetchGetTemplateDraftQuery(variables?: GetTemplateDraftQueryVariables) {
  return { query: GetTemplateDraftDocument, variables: variables };
}
export const ListDocumentsDocument = gql`
  query ListDocuments($input: DocumentsInput) {
    documents(input: $input) {
      id
      title
      updatedAt
      ...DocumentPath
      ...DocumentFolder
    }
  }
  ${DocumentPathFragmentDoc}
  ${DocumentFolderFragmentDoc}
`;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a React component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDocumentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, baseOptions);
}
export function useListDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(
    ListDocumentsDocument,
    baseOptions
  );
}
export type ListDocumentsQueryHookResult = ReturnType<typeof useListDocumentsQuery>;
export type ListDocumentsLazyQueryHookResult = ReturnType<typeof useListDocumentsLazyQuery>;
export type ListDocumentsQueryResult = ApolloReactCommon.QueryResult<ListDocumentsQuery, ListDocumentsQueryVariables>;
export function refetchListDocumentsQuery(variables?: ListDocumentsQueryVariables) {
  return { query: ListDocumentsDocument, variables: variables };
}
export const GetUserInfoDocument = gql`
  query GetUserInfo($id: ID!) {
    user(id: $id) {
      id
      firstname
      lastname
      username
      email
      roles
      enabled
      lastLogin
      accessGroups {
        id
        title
      }
      customer {
        id
        subscription
        corporation
      }
      lastLogin
    }
  }
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, baseOptions);
}
export function useGetUserInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, baseOptions);
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = ApolloReactCommon.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export function refetchGetUserInfoQuery(variables?: GetUserInfoQueryVariables) {
  return { query: GetUserInfoDocument, variables: variables };
}
export const GetUsersDocument = gql`
  query GetUsers($input: ListUsersInput) {
    users(input: $input) {
      id
      firstname
      lastname
      username
      email
      roles
      enabled
      lastLogin
      accessGroups {
        id
        title
      }
      invitationResendCount
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
}
export function useGetUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
  return { query: GetUsersDocument, variables: variables };
}
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
    updatedUser: updateUser(id: $id, input: $input) {
      __typename
      ... on User {
        id
        roles
        enabled
      }
      ... on UserNotPromoted {
        key
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'DocumentBase',
        possibleTypes: [
          {
            name: 'Document',
          },
          {
            name: 'DocumentDraft',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Folder',
        possibleTypes: [
          {
            name: 'FolderDocuments',
          },
          {
            name: 'FolderTemplates',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'TemplateBase',
        possibleTypes: [
          {
            name: 'Template',
          },
          {
            name: 'TemplateDraft',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'DocumentsTreePayload',
        possibleTypes: [
          {
            name: 'DocumentsTreePayloadResult',
          },
          {
            name: 'NotFound',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PayloadException',
        possibleTypes: [
          {
            name: 'NotFound',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'TemplatesTreePayload',
        possibleTypes: [
          {
            name: 'TemplatesTreePayloadResult',
          },
          {
            name: 'NotFound',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'JobResult',
        possibleTypes: [
          {
            name: 'ArchiveDocumentAttachments',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Job',
        possibleTypes: [
          {
            name: 'ArchiveDocumentAttachments',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserResult',
        possibleTypes: [
          {
            name: 'User',
          },
          {
            name: 'UserNotPromoted',
          },
          {
            name: 'UnhandledError',
          },
        ],
      },
    ],
  },
};
export default result;
