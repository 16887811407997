import { useMemo } from 'react';
import { Role, User as IUser, Customer } from '../generated/graphql';
import { useLocalStorage } from './useStorage';

export interface User extends Partial<IUser> {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isContentadmin: boolean;
  isExampleUser: boolean;
  customer?: Customer | null;
}

export function useUser(): [User, (userObj: IUser | null) => void] {
  const [user, setUser] = useLocalStorage<IUser | null>('user');
  // @ts-ignore
  const isSuperAdmin = useMemo(() => (user && user.roles && user.roles.includes(Role.RoleSuperAdmin)) || false, [user]);
  const isAdmin = useMemo(() => (user && user.roles && user.roles.includes(Role.RoleAdmin)) || false, [user]);
  const isContentadmin = useMemo(() => (user && user.roles && user.roles.includes(Role.RoleContentadmin)) || false, [
    user,
  ]);

  const isExampleUser = useMemo(() => (user && user.customer && user.customer.isExample) || false, [user]);
  const customer = useMemo(() => user?.customer, [user]);

  return [{ ...user, isSuperAdmin, isAdmin, isContentadmin, isExampleUser, customer }, setUser];
}
