import { FolderType } from '../graphQlSchema';
import { DocumentQueryType, QueryType, TemplateQueryType } from '../types';

function isDocument(arg: any): arg is DocumentQueryType {
  return arg === 'document' || arg === 'documentDraft';
}

function isTemplate(arg: any): arg is TemplateQueryType {
  return arg === 'template' || arg === 'templateDraft';
}

export type PossibleFolderType = QueryType | FolderType;

export function getFolderType(type: PossibleFolderType): FolderType | undefined {
  if (FolderType[type as FolderType]) {
    return FolderType[type as FolderType];
  }
  if (isDocument(type)) {
    return FolderType.DOCUMENTS;
  }
  if (isTemplate(type)) {
    return FolderType.TEMPLATES;
  }
  return;
}
