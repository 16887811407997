import { css } from 'styled-components';
interface IndexAble<T> {
  [key: string]: T;
}

interface ISizes<T = number> extends IndexAble<T> {
  Desktop: T;
  Tablet: T;
  Phone: T;
}

export const sizes: ISizes = {
  Desktop: 992,
  Tablet: 768,
  Phone: 576,
};

interface CssMedia<T = typeof css> extends IndexAble<T> {
  minDesktop: T;
  minTablet: T;
  minPhone: T;
  maxDesktop: T;
  maxTablet: T;
  maxPhone: T;
}

// Iterate through the sizes and create a media template
export const cssMedia = Object.keys(sizes).reduce<Partial<CssMedia>>((acc, label) => {
  acc['max' + label] = (...args: any) => {
    // @ts-ignore
    const cssArgs = css(...args);
    return css`
      @media (max-width: ${sizes[label] / 14}em) {
        ${cssArgs}
      }
    `;
  };

  let minWidth = 0;
  if (label === 'Tablet') {
    minWidth = sizes.Phone;
  }
  if (label === 'Desktop') {
    minWidth = sizes.Tablet;
  }

  acc['min' + label] = (...args: any) => {
    // @ts-ignore
    const cssArgs = css(...args);
    return css`
      @media (min-width: ${(minWidth + 1) / 14}em) {
        ${cssArgs}
      }
    `;
  };

  return acc;
}, {}) as CssMedia;
