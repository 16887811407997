// Must be the first line
import '@formatjs/intl-relativetimeformat/locale-data/cs';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import 'core-js/features/array/fill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/includes';
import 'core-js/features/object/entries';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/modules/es.symbol';
import 'core-js/web/url-search-params';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import './i18n';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
