import Spinner from '@atlaskit/spinner';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled('div')`
  min-height: 100%;
  min-width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled('div')`
  min-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.typography.h200};
`;

export default ({ children = '', className }: { children?: any; className?: string }) => (
  <StyledWrapper className={className}>
    <StyledLoader>
      <Spinner size="large" />
      {children}
    </StyledLoader>
  </StyledWrapper>
);
