import { createContext, useContext } from 'react';
import { sizes } from '../../utils/cssMedia';

interface IDeviceContext {
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isPhone: boolean;
}
const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const Device = createContext<IDeviceContext>({
  isMobile: pageWidth < sizes.Desktop,
  isDesktop: pageWidth >= sizes.Desktop,
  isTablet: pageWidth < sizes.Desktop && pageWidth > sizes.Phone,
  isPhone: pageWidth <= sizes.Phone,
});

export function useDevice() {
  return useContext(Device);
}

export default Device.Provider;
